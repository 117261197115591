import React from "react";
// import Tag8Logo from "../../assets/genielogofinal.png";
import "./footer.component.css";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
// import amazon from "../../assets/amazon.png";
// import flipkart from "../../assets/flipkart.jpeg";
// import Headsup from "../../assets/genievectors/checkoutLogo.svg";
// import meesho from "../../assets/e-commerce_logo/Meesho_logo.jpg";
// import tatacliq from "../../assets/e-commerce_logo/tatacliq.jpg";
// import paytm from "../../assets/e-commerce_logo/logo_paytm.png";

function footer() {
  return (
    <>
      <div className="footer-div">
        <div className="row inner-footer-div">
          <div
            className="footer-div-1 col-lg-4 col-md-4 col-sm-12 col-12"
            style={{ paddingLeft: "20px", textAlign: "left" }}
          >
            <center>
              <img
                style={{
                  height: "auto",
                  width: "80px",
                  marginTop: "15px",
                  marginBottom: "5px",
                  filter: "brightness(0.3)",
                }}
                src={
                  "https://storage.googleapis.com/pettag/qr/genielogofinal.png"
                }
                alt="tag8"
              />
            </center>
            <hr style={{ width: "100%", border: "1px solid gray" }} />
            <p
              style={{
                fontSize: "13px",
                paddingRight: "20px",
                fontWeight: 200,
              }}
            >
              The endeavour to 'Make Finding Smarter' drives the philosophy at
              tag8. Our solutions facilitate the finding and return of misplaced
              or lost valuables. So while you are occupied in your pursuits,
              tag8 ensures that your valuables are secured.
            </p>
          </div>

          <div
            className="footer-div-2 col-lg-2 col-md-2 col-sm-12 col-12"
            style={{ textAlign: "left" }}
          >
            <br />
            <h5>
              <strong>Contact Us</strong>
            </h5>
            <br />
            <div style={{ textAlign: "left" }}>
              <strong>Address : </strong>
              <span>
                3Y Ventures LLP 301, A to Z Industrial Estate, Ganapatrao Kadam
                Marg, Lower Parel, Mumbai, Maharashtra 400013
              </span>
              <br />

              <div style={{ paddingTop: "15px" }}>
                <strong>Email : </strong>
                <span>
                  <a href="mailto:support@tag8.in" style={{ color: "black" }}>
                    support@tag8.in
                  </a>
                </span>
              </div>

              <div style={{ paddingTop: "15px" }}>
                <strong>Phone : </strong>
                <span>
                  <a href="tel:+919029008248" style={{ color: "black" }}>
                    9029008248
                  </a>
                </span>
              </div>
              <div
                className="footer-div4-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "0px",
                  paddingTop: "10px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    borderRadius: "3px",
                    marginRight: "10px",
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <a
                    href="https://www.facebook.com/geniesmartpettag"
                    target="_blank"
                  >
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <FaFacebookF style={{ marginTop: "9px" }}></FaFacebookF>
                    </button>
                  </a>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    border: "1px solid black",
                    borderRadius: "3px",
                    marginRight: "10px",
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <a
                    href="https://www.instagram.com/geniepetcommunity/"
                    target="_blank"
                  >
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <AiOutlineInstagram
                        style={{ marginTop: "9px" }}
                      ></AiOutlineInstagram>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="footer-div-3 col-lg-3 col-md-3 col-sm-12 col-12"
            style={{
              height: "200px",
              paddingLeft: "15px",
              textAlign: "left",
            }}
          >
            <br />
            <h5>
              <strong>Find Our Products At</strong>
            </h5>
            <br />
            <div
              className="footer-div4-3"
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "5px",
              }}
            >
              <div className="online-store">
                <a
                  href="https://www.amazon.in/tag8-solution-enabled-Missing-Vaccination/dp/B08TC8RQG7"
                  target="_blank"
                >
                  <img
                    src={"https://storage.googleapis.com/pettag/qr/amazon.png"}
                  ></img>
                </a>
              </div>
              <div className="online-store">
                <a
                  href="https://www.flipkart.com/tag8-genie-smart-pet-tag-embellished-dog-cat-collar-charm/p/itmc8c23bfa7c12f?pid=PCCFZKEGU7ZPUC56&lid=LSTPCCFZKEGU7ZPUC56RN2NUE&marketplace=FLIPKART&cmpid=content_pet-collar-charm_12884797849_u_8965229628_gmc_pla&tgi=sem,1,G,11214002,u,,,517829293436,,,,c,,,,,,,&ef_id=CjwKCAjw1JeJBhB9EiwAV612y2B33DvJJh7wSijnZI3ocXv92a9IzFxKbqqyK5-TQPl3O0jxu17OPxoC0f0QAvD_BwE:G:s&s_kwcid=AL!739!3!517829293436!!!u!293946777986!&gclid=CjwKCAjw1JeJBhB9EiwAV612y2B33DvJJh7wSijnZI3ocXv92a9IzFxKbqqyK5-TQPl3O0jxu17OPxoC0f0QAvD_BwE"
                  target="_blank"
                >
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/flipkart.jpeg"
                    }
                  ></img>
                </a>
              </div>
            </div>
            <br />
            <br />
            <div
              className="footer-div4-3"
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "5px",
              }}
            >
              <div className="online-store " style={{ width: "10rem" }}>
                <a
                  href="https://headsupfortails.com/products/genie-smart-pet-tag"
                  target="_blank"
                >
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/checkoutLogo.svg"
                    }
                    style={{ marginTop: "-30px" }}
                  ></img>
                </a>
              </div>
            </div>
          </div>
          <div
            className="footer-div-4 col-lg-4 col-md-4 col-sm-12 col-12"
            style={{ height: "60px" }}
          ></div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "64px",
          backgroundColor: "#272730",
          textAlign: "left",
          color: "white",
          paddingTop: "5px",
          paddingLeft: "15px",
        }}
      >
        Copyright {new Date().getFullYear()} tag8 - 3Y Ventures LLP | All Rights
        Reserved |{" "}
        <a
          href="https://www.tag8.in/page/terms-and-conditions"
          style={{ color: "white" }}
        >
          Legal Terms & Conditions
        </a>{" "}
        |{" "}
        <a href="https://genie.tag8.in/faq" style={{ color: "white" }}>
          FAQ
        </a>
      </div>
    </>
  );
}

export default footer;
