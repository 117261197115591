import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./edithumandetails.component.css";
// import CatDog from "../../assets/catdog1.jpg";
// import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  Breadcrumbs,
  Link,
  List,
  ListItemText,
  Collapse,
  InputAdornment,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import PetImage from "../../assets/pet.png";
// import Loader from "../../assets/loader.gif";
import { ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const baseURL = switchUrls("human");

const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
      width: 25,
    },
    width: 25,
  },

  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
var today = new Date();

const dd = String(today.getDate()).padStart(2, "0");
const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
const yyyy = today.getFullYear();
const dtoday = yyyy + "-" + mm + "-" + dd;

export default class EditHumanDetails extends React.Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      imagesArray: "",
      imageUrl: "",
      pet_imagelocation: null,
      pet_imagename: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      typeof_member: "",
      name: "",
      blood_group: "",
      gender: "",
      date_of_birth: "",
      age: "",
      identity_mark: "",
      skin_color: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      redirecttopetparent: true,
      file: [null],

      sidebar: true,
      subMenu: true,
      showPetDetails: true,
      showPet_ParentDetails: false,
      isUploadingImg: false,

      imageUrlvector:
        "",
      insurance_number: "",
      insurance_company: "",
      insurance_validity:"",
      doctor_firstname: "",
      doctor_lastname:"",
      doctor_contact: "",
      doctor_phcode: "+91",
      showLoader: false,
      first_name:"",
      last_name:"",
      menu1:true,
      menu2:true,
      menu3:true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  componentDidMount() {
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-2").on("click", function () {
      $("#choose-profile-imageActi-2").trigger("click");
    });

    // console.log(this.props.match.params.tag_number, "tagunenen");
    axios
      .get(
        `${baseURL}/api/human/getguardianDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log("res: ", response);

        this.setState({
          uid: response.data.uid,
          typeof_member: response?.data?.typeof_member,
          name: response?.data?.name,
          blood_group: response?.data?.blood_group,
          gender: response?.data?.gender,
          date_of_birth: response?.data?.date_of_birth,
          age: response?.data?.age,
          identity_mark: response?.data?.identity_mark,
          skin_color: response?.data?.skin_color,
          insurance_company: response?.data?.insurance_company,
          insurance_number: response?.data?.insurance_number,
          insurance_validity:response?.data?.insurance_validity,
          doctor_contact: response?.data?.doctor_contact,
          doctor_firstname: response?.data?.doctor_firstname,
          doctor_lastname : response?.data?.doctor_lastname,
          doctor_phcode: response?.data?.doctor_phcode,
          imageUrlvector:response?.data?.human_imagelocation,
          first_name:response?.data?.first_name,
          last_name:response?.data?.last_name

        });

        // if (response.data.parent_firstname === undefined || "") {
        //   this.setState({ redirecttopetparent: true });
        // } else {
        //   this.setState({ redirecttopetparent: false });
        // }
        if (response.data.valuable_type === "Dog") {
          this.setState({
            imageUrlvector:
              "https://storage.googleapis.com/pettag/qr/assets/dog.png",
          });
        } else if (response.data.valuable_type === "Cat") {
          this.setState({
            imageUrlvector:
              "https://storage.googleapis.com/pettag/qr/assets/cat.png",
          });
        }
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }

  handleDropdownChange(event) {
    // console.log("valueType: ", event);
    this.setState({
      valuable_type: event.target.value,
      imageUrlvector: this.state.valuableImages[event.target.value],
      pet_imagelocation: null,
      imageUrl: null,
      isUploadingImg: false,
    });
  }

  handleChange(event) {
    // console.log(event, "click");
    if (event.target.files[0]) {
      this.setState({
        message: "",
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
        pet_imagelocation: null,
        imageUrlvector: this.state.imageUrlvector,
        isUploadingImg: true,
      });
    }
  }

  changeHandlerdate = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    const birthDate = new Date(e.target.value);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);
    const ageyear = Math.abs(age.getUTCFullYear() - 1970);

    this.setState({ age: ageyear });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  deleteimage = (e) => {
    if(this.state.imageUrlvector){
    this.setState({ showLoader:true})
    
    axios
      .post(`${baseURL}/api/human/deleteimage`, this.state,{ headers: { Authorization: usertoken } })
      .then((response) => {
        if (response.data === "human Image removed") {
          this.setState({
            imageUrlvector: null,
            pet_imagelocation: null,
            pet_imagename: null,
          });
          this.setState({ showLoader:false})
        } else if (response.data === "wrong data") {
          this.setState({ showLoader:false})
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ showLoader:false})
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
        window.location.href = '/'
      });

    this.setState({
      imageUrl: null,
      pet_imagelocation: null,
      pet_imagename: null,
      imageUrlvector: this.state.imageUrlvector,
      isUploadingImg: false,
    });
  }
  };

  submitHandler = (e) => {
    e.preventDefault();
      this.setState({ showLoader:true})
    axios
      .post(
        `${baseURL}/api/human/edithumandetails/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        if (response.data === "Human Details Updated") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Human Details Updated",
          });
          if(this.state.selectedImage){

            const fd = new FormData();

            fd.append("image", this.state.selectedImage);
            fd.append("tag_number", this.props.match.params.tag_number);
            axios.post(`${baseURL}/api/human/humanimage-upload`, fd,  { headers: { Authorization: usertoken } }).then((res)=>{
              this.setState({ showLoader:false})
              this.setState({
                snackbaropen: true,
                snackbarmsg: "image updated",
              });
              this.state.redirecttopetparent
              ? (window.location.href =
                  "/#/guardian-details/" + this.props.match.params.tag_number)
              : (window.location.href =
                  "/#/dashboard/" + localStorage.getItem("uid"));
            }).catch((er)=>{
              this.setState({ showLoader:false})
              this.setState({
                snackbaropen: true,
                snackbarmsg: "image upload failed",
              });
            })

          }
          else{
            this.setState({ showLoader:false})
            this.state.redirecttopetparent
            ? window.location.href =
                "/#/guardian-details/" + this.props.match.params.tag_number
            : window.location.href =
                "/#/dashboard/" + localStorage.getItem("uid")
          }
          /*   const fd = new FormData();

          fd.append("image", this.state.selectedImage);
          fd.append("tag_number", this.props.match.params.tag_number);

          axios
            .post(`${baseURL}/api/pet/petimage-upload`, fd)
            .then((res) => {
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Human Details Updated",
              });*/

         

          /*  let formData = new FormData();

              for (const key of Object.keys(this.state.imagesArray)) {
                formData.append("images", this.state.imagesArray[key]);
              }

              axios
                .post(
                  `${baseURL}/api/pet/uploadMultipleImage/` +
                    this.state.tag_number,
                  formData
                )
                .then((res) => {
                  console.info("Image uploaded : ", res);
                })
                .catch((error) => {
                  console.error(error.message);
                });
            })
            .catch((error) => {
              console.error(error.message);
            });*/
        } else if (response.data === "wrong data") {
          this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });

        console.error(error.message);
      });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  phonehandler = (value) => {
    try {
      const countryCodeRegex = /^\+[0-9]+/;
      const phoneNumberRegex = /(?<=^\+)[0-9\s()-]+(?=\s|$)/;

      const extractedCountryCode = value?.match(countryCodeRegex);
      const extractedPhoneNumber = value?.match(phoneNumberRegex);
      if (extractedCountryCode && extractedPhoneNumber) {
        const countryCode = extractedCountryCode[0].replace(/\s/g, ""); // Update the regex to remove spaces
        let phoneNumberWithCountryCode = extractedPhoneNumber.input
          .replace(/\s/g, "")
          .trim();
        console.log(countryCode);
        console.log(phoneNumberWithCountryCode);
        // Special case for +16648879871678
        if (phoneNumberWithCountryCode === countryCode) {
          console.log("Special case: Phone number is same as country code");
          this.setState({
            doctor_contact: "",
            doctor_phcode: countryCode,
          });
        } else if (phoneNumberWithCountryCode.startsWith(countryCode)) {
          phoneNumberWithCountryCode = phoneNumberWithCountryCode.substring(
            countryCode.length
          );
          this.setState({
            doctor_contact: phoneNumberWithCountryCode,
            doctor_phcode: countryCode,
          });
        } else {
          this.setState({
            doctor_contact: phoneNumberWithCountryCode,
            doctor_phcode: countryCode,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Breadcrumb handle click
  swithBreadcrumbs = (e, tab) => {
    // if (tab === "PET_DETAILS") {
    //   this.setState({ showPetDetails: true });
    //   this.setState({ showPet_ParentDetails: false });
    // }
    if (tab === "GUARDIAN_DETAILS") {
      window.location.href =
        "/#/guardian-details/" + this.props.match.params.tag_number;
    }
  };

  render() {
    const {
      sidebar,
      subMenu,
      imageUrl,
      tag_number,
      typeof_member,
      name,
      blood_group,
      gender,
      date_of_birth,
      age,
      identity_mark,
      skin_color,
      pet_imagelocation,
      isUploadingImg,
      imageUrlvector,
      insurance_number,
      insurance_company,
      insurance_validity,
      doctor_firstname,
      doctor_lastname,
      doctor_contact,
      doctor_phcode,
      first_name,
      last_name,
      menu1,
      menu2,
      menu3,
    } = this.state;
    // console.log("state: ", this.state);
    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };
    return (
      <>
        <Navbar />

        <div className="editPetDetails-main-wrapper">
          <div className="editPetDetails-left-wrapper">
            <div className="editPetDetails-sidebar-toggle-btn-wrapper">
              <button
                className="editPetDetails-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "editPetDetails-sidebar-container-hide"
                  : "editPetDetails-sidebar-container"
              }
            >
              <button
                className="editPetDetails-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "editPetDetails-sidebar-menu-ul-hide"
                    : "editPetDetails-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="editPetDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    All Tags
                  </button>
                </li>

                <li>
                  <button
                    className="editPetDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Active | Inactive Tags
                  </button>
                </li>

                <li>
                  <button
                    className="editPetDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Lost | Found Tags
                  </button>
                </li>
              </ul>

              <button
                className="editPetDetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/human/medical-dashboard/" +
                    this.props.match.params.tag_number;
                }}
              >
                MEDICAL DETAILS
              </button>

              <button
                className="editPetDetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/guardian-details/" + this.props.match.params.tag_number;
                }}
              >
                GUARDIAN DETAILS
              </button>
            </div>
          </div>

          <div className="form-group multi-preview">
            {(this.fileArray || []).map((url) => (
              <img
                src={url}
                className="ml-4 mr-2"
                alt="..."
                height="70px"
                width="70px"
              />
            ))}
          </div>

          <div className="editPetDetails-right-wrapper">
           
            <div className="petDetails ">
              <div>
              
                <div >
               

                  <form  onSubmit={this.submitHandler}>
                  <div>
                    <List>
                      <ListItemButton onClick={() => this.setState({menu1:!menu1})}>
                        <ListItemText>
                          <h5>Personal Information</h5>
                        </ListItemText>
                        {menu1 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </List>
                    <Collapse in={menu1}>
                      <div className="personalinfdiv">
                        <div className="imagboxicon" style={{ width: "50%" }}>
                          <div className="editPetDetails-user-img">
                          {pet_imagelocation ? (
                      <img
                        alt="pet-img-uploaded"
                        src={pet_imagelocation}
                        className="editPetDetails-image rounded-circle"
                      />
                    ) : isUploadingImg ? (
                      <img
                        alt="pet-img-uploadeding"
                        src={imageUrl}
                        className="editPetDetails-image rounded-circle"
                      />
                    ) : (
                      <img
                      
                        alt="pet-img-default" s
                        src={imageUrlvector ? imageUrlvector : "https://png.pngtree.com/png-vector/20190621/ourmid/pngtree-human-pose-icon-graphic-design-template-vector-png-image_1497707.jpg"}
                        className="editPetDetails-image"
                        style={{ padding: "1.5rem",borderRadius:"50%" }}
                      />
                    )}
                          </div>
                          <div className="option" style={{ display: "flex" }}>
                            <div>
                              <label
                                htmlFor="upload"
                                className="optionbtn"
                                style={{ cursor: "pointer" }}
                              >
                                <MdIcons.MdEdit /> Change
                              </label>
                              <input
                                style={{ display: "none" }}
                                id="upload"
                                type="file"
                                onChange={this.handleChange}
                              />
                            </div>
                            <div>
                            <label
                      className="optionbtn"
                      onClick={this.deleteimage}
                    >
                      <AiIcons.AiFillDelete /> Remove
                    </label>
                            </div>
                          </div>
                        </div>
                        <div className="details">
                          <div className="two-field-wrapper">
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                variant="outlined"
                                type="text"
                                label="Member Firstname"
                                name="first_name"
                                value={first_name}
                                onChange={this.changeHandler}
                                required
                              ></TextField>
                            </div>

                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                label="Member Lastname"
                                type="text"
                                variant="outlined"
                                maxLength="20"
                                name="last_name"
                                value={last_name}
                                onChange={this.changeHandler}
                                required
                              />
                            </div>
                          </div>
                          <div
                            className="two-field-wrapper"
                            // style={{ marginBottom: "-10px" }}
                          >
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                select
                                label="Blood Group"
                                type="text"
                                variant="outlined"
                                maxLength="3"
                                name="blood_group"
                                value={blood_group}
                                onChange={this.changeHandler}
                                required
                              >
                                <MenuItem value="A+">A+</MenuItem>
                                <MenuItem value="A-">A-</MenuItem>
                                <MenuItem value="B+">B+</MenuItem>
                                <MenuItem value="B-">B-</MenuItem>
                                <MenuItem value="O+">O+</MenuItem>
                                <MenuItem value="O-">O-</MenuItem>
                                <MenuItem value="AB+">AB+</MenuItem>
                                <MenuItem value="AB-">AB-</MenuItem>
                              </TextField>
                            </div>

                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                select
                                label="Gender"
                                type="text"
                                variant="outlined"
                                maxLength="3"
                                name="gender"
                                value={gender}
                                onChange={this.changeHandler}
                                required
                              >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                              </TextField>
                            </div>
                          </div>

                          <div className="two-field-wrapper">
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                label="Date of Birth"
                                variant="outlined"
                                type="date"
                                name="date_of_birth"
                                value={date_of_birth}
                                onChange={this.changeHandlerdate}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: new Date().toISOString().split("T")[0],
                                }}
                              />
                            </div>

                            <div
                              className="editPetDetails-input-wrapper"
                              id="select-pet-age"
                            >
                              <TextField
                                label="Age"
                                type="number"
                                variant="outlined"
                                // className="editPetDetails-input-tag"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={age}
                                onChange={this.changeHandler}
                                name="age"
                              />
                            </div>
                          </div>

                          <div className="two-field-wrapper">
                            <div style={{ width: "100%" }}>
                              <TextField
                                label="Identification Mark"
                                variant="outlined"
                                type="text"
                                // className="editPetDetails-input-tag"
                                maxLength="20"
                                value={identity_mark}
                                name="identity_mark"
                                
                                onChange={this.changeHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>

                    <List>
                      <ListItemButton onClick={() => this.setState({menu2:!menu2})}>
                        <ListItemText>
                          <h5>Insurance Details</h5>
                        </ListItemText>
                        {menu2 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </List>
                    <Collapse in={menu2}>
                      <div className="sectiondiv">
                        <div className="insurancebox">
                          <TextField
                            label="Insurance Company"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={insurance_company}
                            name="insurance_company"
                            onChange={this.changeHandler}
                          />
                        </div>
                        <div className="insurancebox">
                          <TextField
                            label="Insurance Number"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={insurance_number}
                            name="insurance_number"
                            onChange={this.changeHandler}
                          />
                        </div>
                        <div className="insurancebox">
                          <TextField
                            label="Insurance Validityupto"
                            variant="outlined"
                            type="date"
                            name="insurance_validity"
                            value={insurance_validity}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={this.changeHandler}
                          />
                        </div>
                      </div>
                    </Collapse>
                    <List>
                      <ListItemButton onClick={() => this.setState({menu3:!menu3})}>
                        <ListItemText>
                          <h5>Doctor Details</h5>
                        </ListItemText>
                        {menu3 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </List>
                    <Collapse in={menu3}>
                      <div className="sectiondiv">
                        <div className="doctorbox">
                          <TextField
                            label="Doctor First Name"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={doctor_firstname}
                            onChange={this.changeHandler}
                            name="doctor_firstname"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Dr
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="doctorbox">
                          <TextField
                            label="Doctor Last Name"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={doctor_lastname}
                            name="doctor_lastname"
                            onChange={this.changeHandler}
                          />
                        </div>
                        <div className="doctorbox">
                          <MuiPhoneInput
                            label="Doctor Mobile"
                            defaultCountry="in"
                            value={`+${doctor_phcode} ${doctor_contact}`}
                            onChange={this.phonehandler}
                            regions={[
                              "america",
                              "caribbean",
                              "asia",
                              "middle-east",
                              "oceania",
                              "europe",
                              "africa",
                            ]}
                            variant="outlined"
                            name="guardian_alternate_mobile"
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  edge="end"
                                  aria-label="personal-details-icon"
                                >
                                  <ContactPhoneIcon />
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Collapse>
                    <div className="editPetDetails-btn-row">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                      >
                        Submit
                        {/* {this.state.redirecttopetparent ? "SUBMIT" : "NEXT"} */}
                      </Button>

                      <Button
                        type="button"
                        variant="contained"
                        style={{ width: "100%" }}
                        className="bg-white mt-2 cancel-btn submit-login "
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </form>
               
                </div>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        open={this.state.snackbaropen}
                        autoHideDuration={8000}
                        onClose={this.snackbarClose}
                        message={this.state.snackbarmsg}
                        action={[
                          <IconButton
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                          >
                            X
                          </IconButton>,
                        ]}
                      />
      </>
    );
  }
}
