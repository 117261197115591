import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./addnewrecord.component.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  Breadcrumbs,
  Link,
  List,
  ListItemText,
  Collapse,
  InputAdornment,
} from "@material-ui/core";
import { ToastContainer,toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import MaterialFileUpload from "react-material-file-upload";
import * as AiIcons from "react-icons/ai";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { switchUrls } from "../../../api/index.js";
import axios from "axios";
const baseURL = switchUrls("human");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

const usertoken = readCookie("token");

function AddNewRecord({ closeDialog }) {
  const { tag_number } = useParams();
  const [medicaldetails, setmedicaldetails] = useState({});
  const [value, setvalue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [vaccinationErrorMessage, setVaccinationErrorMessage] = useState("");
const [surgeryErrorMessage, setSurgeryErrorMessage] = useState("");
const [allergyErrorMessage, setAllergyErrorMessage] = useState("");
const [medicationErrorMessage, setMedicationErrorMessage] = useState("");
const [medicalConditionErrorMessage, setMedicalConditionErrorMessage] = useState("");
const [measurementErrorMessage, setMeasurementErrorMessage] = useState("");
  const [vaccinationFiles, setVaccinationFiles] = useState([]);
  const [surgeryFiles, setSurgeryFiles] = useState([]);
  const [allergyFiles, setAllergyFiles] = useState([]);
  const [medicationFiles, setMedicationFiles] = useState([]);
  const [medicalConditionFiles, setMedicalConditionFiles] = useState([]);
  const [measurementFiles, setMeasurementFiles] = useState([]);

  // Handle text input change
  const handelmedical = (e) => {
    setmedicaldetails({
      ...medicaldetails,
      [e.target.name]: e.target.value,
    });
  };

  // Handle file uploads
const handleFileChange = (event) => {
  const file = event[0];
  const fileName = file.name;
  const fileSize = file.size;
  const fileExtension = fileName.split(".").pop().toLowerCase();
  const size = fileSize / (1024 * 1024); // Convert to MB

  const isValidFileExtension = [
    "pdf",
    "docx",
    "docs",
    "png",
    "jpg",
    "jpeg",
    "xlsx",
  ].includes(fileExtension);
  const isValidSize = size < 5;
   const regex = /^[a-zA-Z0-9_. -]+(\.[a-zA-Z]{1,4})$/;
  const isValidFileName = regex.test(fileName);

  // Clear previous error messages
  setVaccinationErrorMessage("");
  setSurgeryErrorMessage("");
  setAllergyErrorMessage("");
  setMedicationErrorMessage("");
  setMedicalConditionErrorMessage("");
  setMeasurementErrorMessage("");

  // Validate file extension
  if (!isValidFileExtension) {
    setMessage(`File with extension .${fileExtension} is not allowed!`);
    return;
  }

  // Validate file size
  if (!isValidSize) {
    setMessage("File size should be less than 5 MB.");
    return;
  }

  // Validate file name
  if (!isValidFileName) {
    setMessage(
    "Invalid file name. Use letters, numbers, _, ., -, and a valid file extension (e.g.,.png, .jpg, .docx)."
  );
    return;
  }

  // Handle the number of files per category
  switch (value) {
    case 0: // Vaccination
      if (vaccinationFiles.length >= 2) {
        setVaccinationErrorMessage("You can only upload up to 2 files for vaccination.");
        return;
      }
      setVaccinationFiles((prev) => [...prev, file]);
      break;

    case 1: // Surgery
      if (surgeryFiles.length >= 2) {
        setSurgeryErrorMessage("You can only upload up to 2 files for surgery.");
        return;
      }
      setSurgeryFiles((prev) => [...prev, file]);
      break;

    case 2: // Allergy
      if (allergyFiles.length >= 2) {
        setAllergyErrorMessage("You can only upload up to 2 files for allergy.");
        return;
      }
      setAllergyFiles((prev) => [...prev, file]);
      break;

    case 3: // Medication
      if (medicationFiles.length >= 2) {
        setMedicationErrorMessage("You can only upload up to 2 files for medication.");
        return;
      }
      setMedicationFiles((prev) => [...prev, file]);
      break;

    case 4: // Medical Condition
      if (medicalConditionFiles.length >= 2) {
        setMedicalConditionErrorMessage("You can only upload up to 2 files for medical condition.");
        return;
      }
      setMedicalConditionFiles((prev) => [...prev, file]);
      break;

    case 5: // Measurement
      if (measurementFiles.length >= 2) {
        setMeasurementErrorMessage("You can only upload up to 2 files for measurement.");
        return;
      }
      setMeasurementFiles((prev) => [...prev, file]);
      break;

    default:
      break;
  }

  // Clear the general message for valid file uploads
  setMessage("");
};



  // Remove file
  const handleRemoveFile = (index, fileType) => {
    switch (fileType) {
      case "vaccination":
        setVaccinationFiles((prevFiles) =>
          prevFiles.filter((_, i) => i !== index)
        );
        break;
      case "surgery":
        setSurgeryFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        break;
      case "allergy":
        setAllergyFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        break;
      case "medication":
        setMedicationFiles((prevFiles) =>
          prevFiles.filter((_, i) => i !== index)
        );
        break;
      case "medicalCondition":
        setMedicalConditionFiles((prevFiles) =>
          prevFiles.filter((_, i) => i !== index)
        );
        break;
      case "measurement":
        setMeasurementFiles((prevFiles) =>
          prevFiles.filter((_, i) => i !== index)
        );
        break;
      default:
        break;
    }
  };

  // Append files to form data
  const appendFilesToFormData = (fd, fieldName, fileArray) => {
    fileArray.forEach((file) => {
      fd.append(fieldName, file);
    });
  };
  const addmedical = async (e) => {
  e.preventDefault();
  setLoading(true);

  const medicalTypes = [
    { type: "Vaccination", files: vaccinationFiles },
    { type: "Surgery", files: surgeryFiles },
    { type: "Allergy", files: allergyFiles },
    { type: "Medication", files: medicationFiles },
    { type: "Medicalcondition", files: medicalConditionFiles },
    { type: "Measurement", files: measurementFiles },
  ];

  try {
    // First make the MedicalActivation API call
    const { data: activationData } = await axios.post(
      `${baseURL}/api/human/MedicalActivation/${tag_number}`,
      medicaldetails,
      { headers: { Authorization: usertoken } }
    );

    const { message, responseIds } = activationData;
    console.log(responseIds, "responseIds");

    const errors = []; // Array to capture any errors during file uploads

    // Prepare file uploads
    const uploadPromises = medicalTypes.map(async (medical) => {
      const { type, files } = medical;

      if (!files || files.length === 0) return; // Skip if no files

      // Access the corresponding ID directly from the responseIds object
      const typeIdObj = responseIds[type.toLowerCase()];
      if (!typeIdObj) return;

      const fd = new FormData();
      appendFilesToFormData(fd, "image", files);

      fd.append("tag_number", tag_number);
      fd.append("Type", type);

      const idFieldMap = {
        Vaccination: "vaccination_id",
        Allergy: "allergy_id",
        Surgery: "surgery_id",
        Medication: "medication_id",
        Medicalcondition: "medicalcondition_id",
        Measurement: "measurement_id",
      };

      const idField = idFieldMap[type];
      if (idField) {
        fd.append(idField, typeIdObj.id); // Add the ID from responseIds
      }

      
      try {
        await axios.post(`${baseURL}/api/human/reports-upload`, fd, {
          headers: { Authorization: usertoken },
        });
      } catch (error) {
        if (error.response) {
          
          const errorMessage = error.response.data.errors?.[0]?.detail || 'An unknown error occurred';
          // console.log(errorMessage, "error");
          toast.error(errorMessage);
        } else {
          // Handle errors that are not from a response
          // console.log('Error', error.message);
          toast.error('An error occurred. Please try again later.');
        }
        errors.push(error); // Capture the error
      }
    });

    await Promise.all(uploadPromises); // Wait for all uploads to complete

    // Reload the window only if there were no errors
    if (errors.length === 0) {
      window.location.reload(); // Reload the page
    }
  } catch (error) {
    toast.error("Error during MedicalActivation: " + error.message);
    console.error("Error during MedicalActivation:", error);
  } finally {
    setLoading(false);
  }
};

  return (
    <>
      <form
        className="addNewRecord-form"
        style={{ width: "100%" }}
        onSubmit={addmedical}
      >
        <Tabs
          value={value}
          onChange={() => setvalue(value)}
          aria-label="icon label tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            "@media (min-width: 960px)": {
              // Hide scroll buttons for screens wider than 960px
              ".MuiTabs-scrollButtons": {
                display: "none",
              },
            },
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
              alt="VaccinationImg"
              className="img-medicla-tab"
            />
            <Tab
              // icon={<PhoneIcon />}
              label="Vaccination"
              value={0}
              onClick={() => setvalue(0)}
            />
          </div>

          <div className="d-flex flex-column align-items-center">
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
              alt="VaccinationImg"
              className="img-medicla-tab"
            />
            <Tab
              // icon={<Favorite />}
              label="Surgery"
              value={1}
              onClick={() => setvalue(1)}
            />
          </div>

          <div className="d-flex flex-column align-items-center">
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
              alt="VaccinationImg"
              className="img-medicla-tab"
            />
            <Tab
              // icon={<PersonPinIcon />}
              label="Allergy"
              value={2}
              onClick={() => setvalue(2)}
            />
          </div>

          <div className="d-flex flex-column align-items-center">
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
              alt="VaccinationImg"
              className="img-medicla-tab"
            />
            <Tab
              // icon={<PhoneIcon />}
              label="Medication"
              value={3}
              onClick={() => setvalue(3)}
            />
          </div>

          <div className="d-flex flex-column align-items-center">
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
              alt="VaccinationImg"
              className="img-medicla-tab"
            />
            <Tab
              // icon={<Favorite />}
              label="Medical Condition"
              value={4}
              onClick={() => setvalue(4)}
            />
          </div>

          <div className="d-flex flex-column align-items-center">
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
              alt="VaccinationImg"
              className="img-medicla-tab"
            />
            <Tab
              // icon={<PersonPinIcon />}
              label="Measurement"
              value={5}
              onClick={() => setvalue(5)}
            />
          </div>
          {/* <ScrollableTabsButtonAuto /> */}
        </Tabs>
        {value === 0 && (
          <div className="addNewRecord-popup-wrapper">
            <div
              style={{ paddingRight: "1rem" }}
              className="addNewRecord-popup-wrapper-div"
            >
              <TextField
                label="Vaccination Name"
                variant="outlined"
                name="Vaccination_name"
                value={medicaldetails?.Vaccination_name}
                onChange={handelmedical}
                required
              />
            </div>
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Doctor First Name"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="vaccinedoctor_firstname"
                value={medicaldetails?.vaccinedoctor_firstname}
                onChange={handelmedical}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Dr</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Doctor Last Name"
                value={medicaldetails?.vaccinedoctor_lastname}
                onChange={handelmedical}
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="vaccinedoctor_lastname"
                required
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Administration Date"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                onChange={handelmedical}
                name="VaccinationAdministration_date"
                value={medicaldetails?.VaccinationAdministration_date}
                type="date"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Notes"
                // multiline
                // rows={2}
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-textarea"
                name="VaccinationNotes"
                value={medicaldetails?.VaccinationNotes}
                onChange={handelmedical}
              />
            </div>
            <div className="addNewRecord-uploadReport-container">
              <label>
                <AiIcons.AiOutlineUpload /> Report
              </label>

              <MaterialFileUpload
                onChange={handleFileChange}
                multiple={false}
                accept={[
                  ".pdf",
                  ".docx",
                  ".docs",
                  ".png",
                  ".jpg",
                  ".jpeg",
                  ".xlsx",
                ]}
                maxFileSize={5 * 1024 * 1024}
              />

              {value === 0 &&
                vaccinationFiles.slice(0, 2).map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      type="button"
                      className="remove-button"
                      onClick={() => handleRemoveFile(index, "vaccination")}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

              {message && <label style={{ color: "red" }}>{message}</label>}
              {value === 0 && vaccinationErrorMessage && (
    <label style={{ color: "red" }}>{vaccinationErrorMessage}</label>
  )}
            </div>
            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}
          </div>
        )}

        {value === 1 && (
          <div className="addNewRecord-popup-wrapper">
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Surgery Name"
                variant="outlined"
                name="Surgery_name"
                value={medicaldetails?.Surgery_name}
                onChange={handelmedical}
                required
              />
            </div>
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Doctor Firstname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="surgerydoctor_firstname"
                value={medicaldetails?.surgerydoctor_firstname}
                onChange={handelmedical}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Dr</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Doctor Lastname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="surgerydoctor_lastname"
                value={medicaldetails?.surgerydoctor_lastname}
                onChange={handelmedical}
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Surgery Date"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Surgery_date"
                value={medicaldetails?.Surgery_date}
                onChange={handelmedical}
                type="date"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControl
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
              >
                <InputLabel>Severity</InputLabel>
                <Select
                  value={medicaldetails?.SurgerySeverity}
                  name="SurgerySeverity"
                  onChange={handelmedical}
                  label="Severity"
                >
                  <MenuItem value="Minor">Minor</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Major">Major</MenuItem>
                  <MenuItem value="Critical">Critical</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Notes"
                variant="outlined"
                rowsMin={2}
                name="SurgeryNotes"
                value={medicaldetails?.SurgeryNotes}
                onChange={handelmedical}
              />
            </div>
            <div className="addNewRecord-uploadReport-container">
              <label>
                <AiIcons.AiOutlineUpload /> Report
              </label>

              <MaterialFileUpload
                onChange={handleFileChange}
                multiple={false}
                accept={[
                  ".pdf",
                  ".docx",
                  ".docs",
                  ".png",
                  ".jpg",
                  ".jpeg",
                  ".xlsx",
                ]}
                maxFileSize={5 * 1024 * 1024}
              />

              {value === 1 &&
                surgeryFiles.slice(0, 2).map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveFile(index, "surgery")}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

              {message && <label style={{ color: "red" }}>{message}</label>}
              {value === 1 && surgeryErrorMessage && (
    <label style={{ color: "red" }}>{surgeryErrorMessage}</label>
  )}
            </div>
          </div>
        )}

        {value === 2 && (
          <div className="addNewRecord-popup-wrapper">
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Allergy Name"
                variant="outlined"
                name="Allergy_name"
                value={medicaldetails?.Allergy_name}
                onChange={handelmedical}
                required
              />
            </div>
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                required
                label="Doctor Firstname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="allergydoctor_firstname"
                value={medicaldetails?.allergydoctor_firstname}
                onChange={handelmedical}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Dr</InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Doctor Lastname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="allergydoctor_lastname"
                value={medicaldetails?.allergydoctor_lastname}
                onChange={handelmedical}
                required
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                label="Diagnosis Date"
                type="date"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="AllergyDiagnosis_date"
                value={medicaldetails?.AllergyDiagnosis_date}
                onChange={handelmedical}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                select
                label="Severity"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="AllergySeverity"
                value={medicaldetails?.AllergySeverity}
                onChange={handelmedical}
                required
              >
                <MenuItem value="RAST 0 - Undetectable">
                  RAST 0 - Undetectable
                </MenuItem>
                <MenuItem value="RAST 1 - Low">RAST 1 - Low</MenuItem>
                <MenuItem value="RAST 2- Moderate">RAST 2- Moderate</MenuItem>
                <MenuItem value="RAST 3 - High">RAST 3 - High</MenuItem>
                <MenuItem value="RAST 4 - Very High">
                  RAST 4 - Very High
                </MenuItem>
                <MenuItem value="RAST 5 - Ultra High">
                  RAST 5 - Ultra High
                </MenuItem>
                <MenuItem value="RAST 6 - Extremely High">
                  RAST 6 - Extremely High
                </MenuItem>
              </TextField>
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                onChange={handelmedical}
                label="Notes"
                variant="outlined"
                rowsMin={2}
                name="AllergyNotes"
                value={medicaldetails?.AllergyNotes}
              />
            </div>
            <div className="addNewRecord-uploadReport-container">
              <label>
                <AiIcons.AiOutlineUpload /> Report
              </label>

              <MaterialFileUpload
                onChange={handleFileChange}
                multiple={false}
                accept={[
                  ".pdf",
                  ".docx",
                  ".docs",
                  ".png",
                  ".jpg",
                  ".jpeg",
                  ".xlsx",
                ]}
                maxFileSize={5 * 1024 * 1024}
              />

              {value === 2 &&
                allergyFiles.slice(0, 2).map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveFile(index, "allergy")}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

              {message && <label style={{ color: "red" }}>{message}</label>}
               {value === 2 && allergyErrorMessage && (
    <label style={{ color: "red" }}>{allergyErrorMessage}</label>
  )}
            </div>
          </div>
        )}

        {value === 3 && (
          <div className="addNewRecord-popup-wrapper">
            <div className="addNewRecord-popup-wrapper-div">
              {/* <label>Medication Name *</label> */}
              <TextField
                type="text"
                label="Medication Name"
                variant="outlined"
                name="Medication_name"
                value={medicaldetails?.Medication_name}
                onChange={handelmedical}
                required
              />
            </div>
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                type="text"
                label="Doctor Firstname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="medicinedoctor_firstname"
                value={medicaldetails?.medicinedoctor_firstname}
                onChange={handelmedical}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Dr</InputAdornment>
                  ),
                }}
              />
              <TextField
                type="text"
                label="Doctor Lastname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="medicinedoctor_lastname"
                value={medicaldetails?.medicinedoctor_lastname}
                onChange={handelmedical}
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
        
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              {/* <label>Medication Start Date *</label> */}
              <TextField
                type="date"
                label="Medication Start Date"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Medication_startdate"
                required
                value={medicaldetails?.Medication_startdate}
                onChange={handelmedical}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label="Medication End Date"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Medication_enddate"
                value={medicaldetails?.Medication_enddate}
                onChange={handelmedical}
                // inputProps={{ min: Medication_startdate }}

                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              {/* <label>Dosage</label> */}
              <TextField
                type="text"
                label="Dosage"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="MedicationDosage"
                value={medicaldetails?.MedicationDosage}
                onChange={handelmedical}
              />
              <TextField
                className="addNewRecord-popup-wrapper-div-textarea"
                label="Notes"
                rowsMin={2}
                variant="outlined"
                name="MedicationNotes"
                value={medicaldetails?.MedicationNotes}
                onChange={handelmedical}
              />
            </div>
            <div className="addNewRecord-uploadReport-container">
              <label>
                <AiIcons.AiOutlineUpload /> Report
              </label>

              <MaterialFileUpload
                onChange={handleFileChange}
                multiple={false}
                accept={[
                  ".pdf",
                  ".docx",
                  ".docs",
                  ".png",
                  ".jpg",
                  ".jpeg",
                  ".xlsx",
                ]}
                maxFileSize={5 * 1024 * 1024}
              />

              {value === 3 &&
                medicationFiles.slice(0, 2).map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveFile(index, "medication")}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

              {message && <label style={{ color: "red" }}>{message}</label>}
              {value === 3 && medicationErrorMessage && (
    <label style={{ color: "red" }}>{medicationErrorMessage}</label>
  )}
            </div>
            {/* <div className="addNewRecord-popup-wrapper-div">
              </div> */}
          </div>
        )}

        {value === 4 && (
          <div className="addNewRecord-popup-wrapper">
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                type="text"
                label="Medical Condition Type"
                variant="outlined"
                name="Medicalcondition_type"
                value={medicaldetails?.Medicalcondition_type}
                onChange={handelmedical}
                required
              />
            </div>
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                type="text"
                label="Doctor Firstname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="medicaldoctor_firstname"
                value={medicaldetails?.medicaldoctor_firstname}
                onChange={handelmedical}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Dr</InputAdornment>
                  ),
                }}
              />
              <TextField
                type="text"
                label="Doctor Lastname"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="medicaldoctor_lastname"
                value={medicaldetails?.medicaldoctor_lastname}
                onChange={handelmedical}
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                type="date"
                label="Diagnosis Date"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="MedicalconditionDiagnosed_date"
                value={medicaldetails?.MedicalconditionDiagnosed_date}
                onChange={handelmedical}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="date"
                label="Diagnosis Enddate"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Medicalcondition_enddate"
                value={medicaldetails?.Medicalcondition_enddate}
                onChange={handelmedical}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                type="text"
                label="Severity"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="MedicalconditionSeverity"
                value={medicaldetails?.MedicalconditionSeverity}
                onChange={handelmedical}
              />
              <TextField
                rowsMin={2}
                label="Notes"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="MedicalconditionNotes"
                value={medicaldetails?.MedicalconditionNotes}
                onChange={handelmedical}
              />
            </div>
            <div className="addNewRecord-uploadReport-container">
              <label>
                <AiIcons.AiOutlineUpload /> Report
              </label>

              <MaterialFileUpload
                onChange={handleFileChange}
                multiple={false}
                accept={[
                  ".pdf",
                  ".docx",
                  ".docs",
                  ".png",
                  ".jpg",
                  ".jpeg",
                  ".xlsx",
                ]}
                maxFileSize={5 * 1024 * 1024}
              />

              {value === 4 &&
                medicalConditionFiles.slice(0, 2).map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() =>
                        handleRemoveFile(index, "medicalCondition")
                      }
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

              {message && <label style={{ color: "red" }}>{message}</label>}
              {value === 4 && medicalConditionErrorMessage && (
    <label style={{ color: "red" }}>{medicalConditionErrorMessage}</label>
  )}
            </div>
          </div>
        )}

        {value === 5 && (
          <div className="addNewRecord-popup-wrapper">
            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                type="number"
                label="Weight (kg)"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Weight"
                value={medicaldetails?.Weight}
                onChange={handelmedical}
                required
              />
              <TextField
                type="number"
                label="Height (cm)"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Height"
                value={medicaldetails?.Height}
                onChange={handelmedical}
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
              
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                type="date"
                label="Measurement Date"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Measurement_date"
                required
                value={medicaldetails?.Measurement_date}
                onChange={handelmedical}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="text"
                label="Measured By"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-input"
                name="Measuredby"
                value={medicaldetails?.Measuredby}
                onChange={handelmedical}
              />
            </div>

            {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

            <div className="addNewRecord-popup-wrapper-div">
              <TextField
                // rowsMin={2}
                label="Notes"
                variant="outlined"
                className="addNewRecord-popup-wrapper-div-textarea"
                name="MeasurementNotes"
                value={medicaldetails?.MeasurementNotes}
                onChange={handelmedical}
              />
            </div>
            <div className="addNewRecord-uploadReport-container">
              <label>
                <AiIcons.AiOutlineUpload /> Report
              </label>

              <MaterialFileUpload
                onChange={handleFileChange}
                multiple={false}
                accept={[
                  ".pdf",
                  ".docx",
                  ".docs",
                  ".png",
                  ".jpg",
                  ".jpeg",
                  ".xlsx",
                ]}
                maxFileSize={5 * 1024 * 1024}
              />

              {value === 5 &&
                measurementFiles.slice(0, 2).map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveFile(index, "measurement")}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

              {message && <label style={{ color: "red" }}>{message}</label>}
              {value === 5 && measurementErrorMessage && (
    <label style={{ color: "red" }}>{measurementErrorMessage}</label>
  )}
            </div>
          </div>
        )}

        <div className="addNewRecord-form-btn-wrapper">
          <button
            type="submit"
            className="addNewRecord-form-btn"
            style={{ backgroundColor: "#ffc107", color: "white" }}
          >
            Submit
          </button>

          <button
            type="button"
            onClick={() => closeDialog(false)}
            className="addNewRecord-form-btn"
            style={{
              color: "black",
            }}
          >
            Close
          </button>
        </div>
        <ToastContainer />
      </form>
    </>
  );
}

export default AddNewRecord;
