import React, { Component } from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import './detail_history.css';
import { Col, Row, Table } from 'react-bootstrap';

export default class Details_history extends Component {
    state = {
        data_history: []
    }
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        fetch('/api/pet/getMedicalNotification/' + this.props.uid).then((response) => response.json()).then(data_list => {
            this.setState({ data_history: data_list });
        })
    }

    render() {
        return (
            <div className="calendar_container_det">
                <div className="Med_tit"> All Medical details </div>
                {/* <div className="inside_detail_con">
              {
                  this.state.data_history.map((data,index)=>(
                      <div className="summary_container" key={index}>
                       <div className="subject"><span>Subject is : </span>{data.Subject}</div>
                       <div className="time_con">
                       <div className="start_time"><span>Start date : </span>{data.StartTime}</div>
                       <div className="end_time"><span>End Date : </span>{data.EndTime}</div>
                       </div>
                        <div className="description"><span>Description : </span>{data.description}</div>
                      </div>
                  ))
              }
              </div> */}
                <Row>
                    <Col>
                        <Table striped bordered hover className="table1">
                            <thead>
                                <tr>
                                    <th>Upcoming</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    console.log(this.state.data_history)
                                }
                                {this.state.data_history.map((data, index) => {
                                    if (new Date(data.StartTime).getTime() > new Date().getTime())
                                        return <tr key={index}><td>{data.Subject}</td></tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <Table striped bordered hover className="table1">
                            <thead>
                                <tr>
                                    <th>Past</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data_history.map((data, index) => {
                                    if ((new Date(data.StartTime).getTime() < new Date().getTime()) && data.Done)
                                        return <tr key={index}><td>{data.Subject}</td></tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <Table striped bordered hover className="table1">
                            <thead>
                                <tr>
                                    <th>Overdue</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data_history.map((data, index) => {
                                    if ((new Date(data.StartTime).getTime() < new Date().getTime()) && !data.Done)
                                        return <tr key={index}><td>{data.Subject}</td></tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}



