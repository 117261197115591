import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../medicaldashboard.component.css";
// import "font-awesome/css/font-awesome.min.css";
// import Measurement from "../../../assets/weight.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import Calendar from "../../../assets/calendar.png";
// import paperclip from "../../../assets/paper-clip.png";
// import File from "../../../assets/attached-file.png";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import axios from "axios";
import "./cards.component.css";
import UpdateRecord from "../update-new-record/updaterecord.component.jsx";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GrIcons from "react-icons/gr";
import DialogTitle from "@mui/material/DialogTitle";
import { switchUrls } from "../../../api/index.js";

const baseURL = switchUrls("genie");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let token = usertoken;

function Measurementcard({
  tag_number,
  Measurement_ReportsLocations,
  Measurement_ReportsNames,
  Measurement_Reportlocation,
  Measurement_Reportname,
  measurement_id,
  Weight,
  Measuredby,
  Measurement_date,
  MeasurementNotes,
  MeasurementTitle,
  MeasurementNext,
  MeasurementReminderNotes,
  activationpage
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showUpdate, setShowUpdate] = useState(false);
  const [docs, setDocs] = useState([]);
  const [show, setShow] = useState(false);
  const [showDocument, setShowDocument] = useState(true);
  let reports = [];

  function setreports() {
    for (let i = 0; i < Measurement_ReportsNames.length; i++) {
      let obj = {
        name: Measurement_ReportsNames[i],
        location: Measurement_ReportsLocations[i],
      };

      reports.push(obj);
    }

    setDocs(reports);
  }

  useEffect(() => {
    setreports();
  }, []);

  const handleCloseUpdate = () => {
    setShowUpdate(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const convertISOStringToMonthDay = (date) => {
    const tempDate = new Date(date).toString().split(" ");
    const formattedDate = `${tempDate[2]} ${tempDate[1]}, ${tempDate[3]}`;

    return formattedDate;
  };

  const handleClose1 = () => {
    setShow(false);
  };

  function deletedetails() {
    const state = {
      tag_number: tag_number,
      measurement_id: measurement_id,
    };

    axios
      .post(`${baseURL}/api/pet/deleteMedicalDetails`, state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  return (
    <>
      <div className="cards-component-wrapper">
        <div className="cards-component-container">
          <div className="cards-component-container-head">
            <img
              src={
                "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
              }
              alt="measurement"
            />
            <h5> Weight : {Weight}kg</h5>
            <div className="tag-number">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                style={{ width: "20px", height: "20px" }}
              />
              <p className="mx-1">{tag_number} </p>
            </div>
            <div className={`cards-component-main-wrapper mobile-view`}>
              <div className="cards-component-main-content">
                <label>Measured on</label>

                <h5>{convertISOStringToMonthDay(Measurement_date)}</h5>
              </div>

              {Measuredby ? (
                <div className="cards-component-main-content">
                  <label>Measured by</label>

                  <h5>{Measuredby}</h5>
                </div>
              ) : (
                ""
              )}
              {MeasurementNotes ? (
                <div className="cards-component-main-content reminder-notes">
                  <label>Notes</label>

                  <h6>{MeasurementNotes}</h6>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="middle-content">
            <div className="cards-component-main-wrapper-flex">
              <div className={`cards-component-main-wrapper laptop-view`}>
                <div className="cards-component-main-content">
                  <label>Measured on</label>

                  <h5>{convertISOStringToMonthDay(Measurement_date)}</h5>
                </div>

                {Measuredby ? (
                  <div className="cards-component-main-content">
                    <label>Measured by</label>

                    <h5>{Measuredby}</h5>
                  </div>
                ) : (
                  ""
                )}
                {MeasurementNotes ? (
                  <div className="cards-component-main-content reminder-notes">
                    <label>Notes</label>

                    <h6>{MeasurementNotes}</h6>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {
             !activationpage ?     <div className="right">
             <div className="cards-component-kebab-menu">
               <Tooltip title="Edit Record">
                 <img
                   src="https://storage.googleapis.com/pettag/qr/assets/edits.png"
                   className="edit-btn"
                   onClick={() => {
                     setShowUpdate(true);
                     handleClose();
                   }}
                 />
               </Tooltip>
               <Tooltip title="Delete Record">
                 <img
                   src="https://storage.googleapis.com/pettag/qr/assets/delete.png"
                   className="delete-btn"
                   onClick={() => {
                     setShow(true);
                     handleClose();
                   }}
                 />
               </Tooltip>
             </div>
           </div> : null
          }

       
        </div>
      </div>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={show}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          {"Are you sure you want to delete this card?"}
        </DialogTitle>

        <div className="cards-component-delete-dialog">
          <button onClick={deletedetails}>Confirm</button>

          <button onClick={() => setShow(false)}>Cancel</button>
        </div>
      </Dialog>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15, maxWidth: "835px" },
        }}
        open={showUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <UpdateRecord
          measurement_id={measurement_id}
          defaultRecord={"MEASUREMENT"}
          medicalTabActive={5}
          closeDialog={handleCloseUpdate}
        />
      </Dialog>
    </>
  );
}

export default Measurementcard;
