import React, { useState } from "react";
import "./photogallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import axios from "axios";
import { useEffect } from "react";
// import CatDog from "../../assets/user.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function PhotoGallery(props) {
  const [multiimages, setmultiimages] = useState(null);
  const [pet_imagelocation, setpet_imagelocation] = useState("");

  useEffect(() => {
    axios
      .get("/api/pet/getPetParentDetails/" + props.match.params.tag_number)
      .then((response) => {
        console.log(response.data);

        setmultiimages(response.data.multipleimages);
        setpet_imagelocation(response.data.pet_imagelocation);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="background py-5" style={{ height: "100vh" }}>
      <div className="row">
        <div
          className="text-dark text-left dashboard__blacksidenav"
          style={{ height: "100vh", marginTop: "-10px" }}
        >
          <div
            className="image-upload-med text-left ml-5 mt-5"
            style={{ marginLeft: "50px" }}
          >
            {pet_imagelocation ? (
              <img
                alt=""
                src={pet_imagelocation}
                className="image"
                style={{ marginLeft: "50px" }}
              />
            ) : (
              <img
                alt=""
                src={"https://storage.googleapis.com/pettag/qr/user.png"}
                className="image"
                style={{ marginLeft: "50px" }}
              />
            )}
          </div>

          <div className="row petparentlinks">
            <div className="col text-left ml-5" style={{ margin: "auto" }}>
              <ul style={{ listStyle: "none", marginLeft: "0rem" }}>
                <li>
                  {/* <a href={`https://dashboard.tag8.in/dashboard/`+localStorage.getItem('uid')}><Button className="dash_btn" style={{'fontSize': '16px !important' , 'color':'#737373' , 'textDecoration':'none'}}>My Tags</Button></a> */}
                  <button
                    className="tag_inside_btn mt-1 font-weight-bold"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    {" "}
                    <span className="tag1_text">MY TAGS</span>
                  </button>

                  <ul style={{ listStyle: "none", marginLeft: "15px" }}>
                    <li>
                      <button
                        className="tag_inside_btn"
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + this.props.match.params.tag_number;
                        }}
                      >
                        <span className="tag1_text">
                          {">  "} &nbsp; ALL TAGS
                        </span>
                      </button>
                    </li>

                    <li>
                      <button
                        className="tag_inside_btn"
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + this.props.match.params.tag_number;
                        }}
                      >
                        <span className="tag1_text">
                          {">  "}&nbsp;ACTIVE | INACTIVE TAGS
                        </span>
                      </button>
                    </li>

                    <li>
                      <button
                        className="tag_inside_btn"
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + this.props.match.params.tag_number;
                        }}
                      >
                        <span className="tag1_text">
                          {">  "} &nbsp; LOST | FOUND TAGS
                        </span>
                      </button>
                    </li>
                  </ul>
                </li>

                <li>
                  <button
                    className="tag_inside_btn font-weight-bold"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    <span className="tag1_text">ACTIVATE TAG</span>
                  </button>
                </li>

                <li>
                  <button
                    className="tag_inside_btn font-weight-bold"
                    onClick={() => {
                      window.location.href =
                        "/calendar/" + this.props.match.params.tag_number;
                    }}
                  >
                    {" "}
                    <span className="tag1_text">CALENDAR</span>
                  </button>
                </li>

                <li>
                  <button
                    className="tag_inside_btn font-weight-bold"
                    onClick={() => {
                      window.location.href =
                        "/scan-notification/" + localStorage.getItem("uid");
                    }}
                  >
                    <span className="tag1_text">NOTIFICATIONS</span>
                  </button>
                </li>

                <li>
                  <button
                    className="tag_inside_btn font-weight-bold "
                    onClick={() => {
                      window.location.href =
                        "/#/pet-parent-details/" +
                        this.props.match.params.tag_number;
                    }}
                  >
                    {" "}
                    <span className="tag1_text">PET PARENT DETAILS</span>
                  </button>
                </li>

                <li>
                  <button
                    className="tag_inside_btn font-weight-bold "
                    onClick={() => {
                      window.location.href =
                        "/#/pet-details/" + this.props.match.params.tag_number;
                    }}
                  >
                    {" "}
                    <span className="tag1_text">PET DETAILS</span>
                  </button>
                </li>

                <li>
                  <button
                    className="tag_inside_btn font-weight-bold "
                    onClick={() => {
                      window.location.href =
                        "/#/medical-dashboard/" +
                        this.props.match.params.tag_number;
                    }}
                  >
                    {" "}
                    <span className="tag1_text">PET MEDICAL DETAILS</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="col-xl-9 col-lg"
          style={{ padding: "20px 50px 300px 50px" }}
        >
          {multiimages &&
            multiimages.map((res) => {
              return (
                <>
                  {res.pet_imagelocation ? (
                    <Zoom>
                      <img
                        className="ml-4 mt-4"
                        src={res.pet_imagelocation}
                        width="250px"
                        height="250px"
                      />
                    </Zoom>
                  ) : (
                    ""
                  )}

                  {/* <button type="button" className="btn" onClick={deleteimage(res._id)}>Delete</button> */}
                </>
              );
            })}
          {/* <Carousel autoPlay interval="5000" transitionTime="2000" className="photogallery_carousel">
                        {
                            multiimages && multiimages.map((res)=>{
                                return(
                                    <div>
                                        <img src={res.pet_imagelocation}/>
                                    </div>
                                )
                            })
                        }
                    </Carousel> */}
        </div>
      </div>
    </div>
  );
}

export default PhotoGallery;
