import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../edithumandetails/edithumandetails.component.css";
// import CatDog from "../../assets/catdog1.jpg";
// import "font-awesome/css/font-awesome.min.css";
import $, { event } from "jquery";
import Button from "@mui/material/Button";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Navbar from "../header/header.component.jsx";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Country, State, City } from "country-state-city";
import Sidebarcontent from "../sidebar/sidebar.component.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  Breadcrumbs,
  Link,
  List,
  ListItemText,
  Collapse,
  InputAdornment,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import PetImage from "../../assets/pet.png";
// import Loader from "../../assets/loader.gif";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Vaccinationcard from "../medicaldashboard/cards/vaccination.component.jsx";
import { ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useParams } from "react-router-dom";

import Surgerycard from "../medicaldashboard/cards/surgery.component.jsx";
import Allergycard from "../medicaldashboard/cards/allergy.component.jsx";
import Medicationcard from "../medicaldashboard/cards/medication.component.jsx";
import Medicalconditioncard from "../medicaldashboard/cards/medicalcondition.component.jsx";
import Measurementcard from "../medicaldashboard/cards/measurement.component.jsx";
import Medicalcard from "../medicaldashboard/cards/Medicalcard.jsx";
import AddNewRecord from "../medicaldashboard/add-new-record/addnewrecord.component.jsx";

const baseURL = switchUrls("human");

const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
      width: 25,
    },
    width: 25,
  },

  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
const basegatewayURL = switchUrls("gateway");
const uid = localStorage.getItem("uid");
var today = new Date();

const dd = String(today.getDate()).padStart(2, "0");
const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
const yyyy = today.getFullYear();
const dtoday = yyyy + "-" + mm + "-" + dd;

function Humanactivation() {
  const { tag_number } = useParams();
  const [tabdisable, settabdisable] = useState(false);
  const [humandetails, sethumandetails] = useState();
  const [medicaldetails, setmedicaldetails] = useState({
    tag_number: tag_number,
  });
  const [sidebar, setsidebar] = useState(true);
  const [submenu, setsubmenu] = useState(true);
  const [values, setvalues] = useState(0);
  const [medicalcnt, setcountmedical] = useState([]);
  const [openmodal, setopenmodal] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarmsg, setsnackbarmsg] = useState("");
  const [medicaltiles, setmedicaltiles] = useState({});
  const [image, setimage] = useState({
    isuploading: false,
    imageurl: "",
    selecedimage: "",
  });
  const [menu1, setmenu1] = useState(true);
  const [menu2, setmenu2] = useState(true);
  const [menu3, setmenu3] = useState(true);
  const [value, setvalue] = useState(0);
  const [formdata, setformdata] = useState({
    tag_number: tag_number,
    blood_group: "",
    gender: "",
  });
  const [loader, setloader] = useState(false);
  const [addnewrec, setnewrec] = useState(false);

  const sectionMap = {
    0: "Vaccination",
    1: "Surgery",
    2: "Allergy",
    3: "Medication",
    4: "Medicalcondition",
    5: "Measurement",
  };
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let usertoken = readCookie("token");
  const skip = async () => {
    setopenmodal(false);
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  };

  const linkapp = async () => {
    if (
      navigator.platform.indexOf("Mac") === 0 ||
      navigator.platform === "iPhone"
    ) {
      window.location.href =
        "https://apps.apple.com/in/app/dolphin-tracker/id1524423508";
    } else {
      window.location.href =
        " https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker";
    }
    setopenmodal(false);
  };
  const addmedical = async (e) => {
    try {
      e.preventDefault();
      setloader(true);

      const { data } = await axios.post(
        `${baseURL}/api/human/MedicalActivation/` + tag_number,
        medicaldetails,
        { headers: { Authorization: usertoken } }
      );
      console.log(data);

      // Scroll to the target div
      const targetDiv = document.getElementById("meddetails");
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
      getallmedical();
      setmedicaldetails({});
      setnewrec(!addnewrec);
      if (value === 5) {
        setvalue(0);
      } else {
        setvalue(value + 1);
      }

      setloader(false);
      setsnackbar(true);
      setsnackbarmsg(`medical Details updated`);
    } catch (error) {
      console.log(error);
      setloader(false);
      setsnackbar(true);
      setsnackbarmsg("failed");
      window.location.href = "/";
    }
  };
  const handelmedical = async (e) => {
    try {
      setmedicaldetails({
        ...medicaldetails,
        [e?.target?.name]: e?.target?.value,
      });
    } catch (error) {}
  };

  let capFirstLetter = (sentenses) => {
    const arr = sentenses?.split(" ");

    for (let i = 0; i < arr?.length; i++) {
      arr[i] = arr[i]?.charAt(0).toUpperCase() + arr[i]?.slice(1);
    }
    return arr?.join(" ");
  };

  let countryNameCapFirstLetter = capFirstLetter(formdata?.guardian_country);

  const countryWithIsoCode = Country?.getAllCountries().filter((v) => {
    return v.name === countryNameCapFirstLetter;
  });

  let stateNameCapFirstLetter = capFirstLetter(formdata?.guardian_state);
  let getStateIsoCode = State?.getStatesOfCountry(
    countryWithIsoCode[0]?.isoCode
  ).filter((v) => {
    return v.name === stateNameCapFirstLetter;
  });

  const handelimgchange = async (e) => {
    if (e?.target?.files[0]) {
      setimage({
        ...image,
        isuploading: true,
        selecedimage: e?.target?.files[0],
        imageurl: URL.createObjectURL(e?.target?.files[0]),
      });
    }
  };
  const phonehandlerguardian = async (value) => {
    try {
      const countryCodeRegex = /^\+[0-9]+/;
      const phoneNumberRegex = /(?<=^\+)[0-9\s()-]+(?=\s|$)/;

      const extractedCountryCode = value?.match(countryCodeRegex);
      const extractedPhoneNumber = value?.match(phoneNumberRegex);
      if (extractedCountryCode && extractedPhoneNumber) {
        const countryCode = extractedCountryCode[0].replace(/\s/g, ""); // Update the regex to remove spaces
        let phoneNumberWithCountryCode = extractedPhoneNumber.input
          .replace(/\s/g, "")
          .trim();
        console.log(countryCode);
        console.log(phoneNumberWithCountryCode);
        // Special case for +16648879871678
        if (phoneNumberWithCountryCode === countryCode) {
          console.log("Special case: Phone number is same as country code");
          setformdata({
            ...formdata,
            guardian_mobile: "",
            guardian_code: countryCode,
          });
        } else if (phoneNumberWithCountryCode.startsWith(countryCode)) {
          phoneNumberWithCountryCode = phoneNumberWithCountryCode.substring(
            countryCode.length
          );
          setformdata({
            ...formdata,
            guardian_mobile: phoneNumberWithCountryCode,
            guardian_code: countryCode,
          });
        } else {
          setformdata({
            ...formdata,
            guardian_mobile: phoneNumberWithCountryCode,
            guardian_code: countryCode,
          });
        }
      }
    } catch (error) {}
  };
  const phonehandlerguardian2 = async (value) => {
    try {
      const countryCodeRegex = /^\+[0-9]+/;
      const phoneNumberRegex = /(?<=^\+)[0-9\s()-]+(?=\s|$)/;

      const extractedCountryCode = value?.match(countryCodeRegex);
      const extractedPhoneNumber = value?.match(phoneNumberRegex);
      if (extractedCountryCode && extractedPhoneNumber) {
        const countryCode = extractedCountryCode[0].replace(/\s/g, ""); // Update the regex to remove spaces
        let phoneNumberWithCountryCode = extractedPhoneNumber.input
          .replace(/\s/g, "")
          .trim();
        console.log(countryCode);
        console.log(phoneNumberWithCountryCode);
        // Special case for +16648879871678
        if (phoneNumberWithCountryCode === countryCode) {
          console.log("Special case: Phone number is same as country code");
          setformdata({
            ...formdata,
            guardian_alternate_mobile: "",
            guardian_alternatecode: countryCode,
          });
        } else if (phoneNumberWithCountryCode.startsWith(countryCode)) {
          phoneNumberWithCountryCode = phoneNumberWithCountryCode.substring(
            countryCode.length
          );
          setformdata({
            ...formdata,
            guardian_alternate_mobile: phoneNumberWithCountryCode,
            guardian_alternatecode: countryCode,
          });
        } else {
          setformdata({
            ...formdata,
            guardian_alternate_mobile: phoneNumberWithCountryCode,
            guardian_alternatecode: countryCode,
          });
        }
      }
    } catch (error) {}
  };

  const phonehandler = async (value) => {
    try {
      const countryCodeRegex = /^\+[0-9]+/;
      const phoneNumberRegex = /(?<=^\+)[0-9\s()-]+(?=\s|$)/;

      const extractedCountryCode = value?.match(countryCodeRegex);
      const extractedPhoneNumber = value?.match(phoneNumberRegex);
      if (extractedCountryCode && extractedPhoneNumber) {
        const countryCode = extractedCountryCode[0].replace(/\s/g, ""); // Update the regex to remove spaces
        let phoneNumberWithCountryCode = extractedPhoneNumber.input
          .replace(/\s/g, "")
          .trim();
        console.log(countryCode);
        console.log(phoneNumberWithCountryCode);
        // Special case for +16648879871678
        if (phoneNumberWithCountryCode === countryCode) {
          console.log("Special case: Phone number is same as country code");
          setformdata({
            ...formdata,
            doctor_contact: "",
            doctor_phcode: countryCode,
          });
        } else if (phoneNumberWithCountryCode.startsWith(countryCode)) {
          phoneNumberWithCountryCode = phoneNumberWithCountryCode.substring(
            countryCode.length
          );
          setformdata({
            ...formdata,
            doctor_contact: phoneNumberWithCountryCode,
            doctor_phcode: countryCode,
          });
        } else {
          setformdata({
            ...formdata,
            doctor_contact: phoneNumberWithCountryCode,
            doctor_phcode: countryCode,
          });
        }
      }
    } catch (error) {}
  };
  const handelchange = async (e) => {
    setformdata({ ...formdata, [e?.target?.name]: e?.target?.value });
  };
  const handeldatechange = async (e) => {
    try {
      console.log("changed");
      setformdata({ ...formdata, [e?.target?.name]: e?.target?.value });
    } catch (error) {
      console.log(error);
    }
  };

  const handeldatechange2 = async (e) => {
    try {
      console.log(e?.target?.name, e?.target?.value);

      const birthDate = new Date(e.target.value);
      const difference = Date.now() - birthDate.getTime();
      const age = new Date(difference);
      const ageyear = Math.abs(age.getUTCFullYear() - 1970);
      setformdata({
        ...formdata,
        [e?.target?.name]: e?.target?.value,
        age: ageyear,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handelsubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(formdata);
      setloader(true);
      const { data } = await axios.post(
        `${baseURL}/api/human/humanactivation`,
        formdata,
        {
          headers: { Authorization: usertoken },
        }
      );
      console.log(data);
      if (data === "Tag Already Activated") {
        setsnackbar(true);
        setsnackbarmsg(data);
      } else if (data === "Tag not Found by the specified Number") {
        setsnackbar(true);
        setsnackbarmsg(data);
      } else if (data === "Human Details Registered") {
        if (image?.selecedimage) {
          setloader(true);
          const fd = new FormData();
          fd.append("image", image?.selecedimage);
          fd.append("tag_number", tag_number);
          axios
            .post(`${baseURL}/api/human/humanimage-upload`, fd, {
              headers: { Authorization: usertoken },
            })
            .then((data) => {
              setloader(false);
              setsnackbar(true);
              setsnackbarmsg("human Details Registered");
              setvalues(1);
            })
            .catch((er) => {
              console.log(er);
            });
        } else {
          setvalues(1);
          setloader(false);
          setsnackbar(true);
          setsnackbarmsg("human Details Registered");
        }
      } else {
        setvalues(1);
        setloader(false);
        setsnackbar(true);
        setsnackbarmsg("human Details Registered");
      }
    } catch (error) {
      console.log(error);
      setloader(false);
      setsnackbar(true);
      setsnackbarmsg("Failed");
      window.location.href = "/";
    }
  };
  const guardiansubmit = async (e) => {
    try {
      e.preventDefault();
      setloader(true);
      const { data } = await axios.post(
        `${baseURL}/api/human/guardiandetails/` + tag_number,
        formdata,
        { headers: { Authorization: usertoken } }
      );
      setloader(false);
      setsnackbar(true);
      setsnackbarmsg("guardian Details Updated");
      setvalues(2);
    } catch (error) {
      setsnackbar(true);
      setsnackbarmsg("Failed");
      window.location.href = "/";
    }
  };
  const fetchdetails = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/human/getguardianDetails/` + tag_number
      );
      console.log(data);
      if (data?.tag_number) {
        setformdata(data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getallmedical = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/human/getMedicalDetails/` + tag_number
      );
      console.log(data);
      setmedicaltiles(data);
    } catch (error) {
      setloader(false);
    }
  };
  const medicalcount = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/human/countMedicalDetails/` + tag_number
      );
      console.log(data);
      setcountmedical(data);
    } catch (error) {}
  };
  useEffect(() => {
    getallmedical();
    fetchdetails();
    console.log(",,,,,,,,,");
    console.log(formdata);
  }, [value]);
 const handleShow = () => {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  };

  const handleLostFound = () => {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  };

  const handleAllTags = () => {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  };

  const handleActiveInactive = () => {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  };
  return (
    <>
      <Navbar />

      <div className="editPetDetails-main-wrapper">
        <div className="editPetDetails-left-wrapper">
          <Sidebarcontent
          handleShow={handleShow}
          handleAllTags={handleAllTags}
          handleActiveInactive={handleActiveInactive}
          handleLostFound={handleLostFound}
          extraButtonStyle="extraButtonStyle" />
        </div>

        {/* <div className="form-group multi-preview"></div> */}

        <div className="editPetDetails-right-wrapper">
          <div id="breadcrumbs" className="mb-3">
            <Tabs
              value={values}
              onChange={() => setvalues(values)}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                "@media (min-width: 960px)": {
                  // Hide scroll buttons for screens wider than 960px
                  ".MuiTabs-scrollButtons": {
                    display: "none",
                  },
                },
              }}
            >
              <Tab
                value={0}
                onClick={() => setvalues(0)}
                label="Basic Information"
              />
              <Tab
                value={1}
                onClick={() => setvalues(1)}
                label="Guardian Details"
              />
              <Tab
                value={2}
                onClick={() => setvalues(2)}
                label="Medical Details"
              />
            </Tabs>
          </div>
          {values === 0 ? (
            <>
              <div className="petDetails ">
                <form onSubmit={handelsubmit}>
                  <div>
                    <List>
                      <ListItemButton onClick={() => setmenu1(!menu1)}>
                        <ListItemText>
                          <h5>Personal Information</h5>
                        </ListItemText>
                        {menu1 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </List>
                    <Collapse in={menu1}>
                      <div className="personalinfdiv">
                        <div className="imagboxicon" style={{ width: "50%" }}>
                          <div className="editPetDetails-user-img">
                            {image?.isuploading ? (
                              <img
                                alt="pet-img-uploadeding"
                                src={image?.imageurl}
                                className="editPetDetails-image rounded-circle"
                              />
                            ) : (
                              <img
                                alt="pet-img-default"
                                s
                                src={
                                  "https://storage.googleapis.com/pettag/qr/assets/human.png"
                                }
                                className="editPetDetails-image"
                                style={{
                                  padding: "1.5rem",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          </div>
                          <div className="option" style={{ display: "flex" }}>
                            <div>
                              <label
                                htmlFor="upload"
                                className="optionbtn"
                                style={{ cursor: "pointer" }}
                              >
                                <MdIcons.MdEdit /> Change
                              </label>
                              <input
                                style={{ display: "none" }}
                                id="upload"
                                type="file"
                                onChange={handelimgchange}
                                accept="image/*"
                              />
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div className="details">
                          <div className="two-field-wrapper">
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                variant="outlined"
                                type="text"
                                label="Member Firstnames"
                                name="first_name"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={formdata?.first_name}
                                onChange={handelchange}
                                required
                              ></TextField>
                            </div>

                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                label="Member Lastname"
                                type="text"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                maxLength="20"
                                name="last_name"
                                value={formdata?.last_name}
                                onChange={handelchange}
                                required
                              />
                            </div>
                          </div>
                          <div
                            className="two-field-wrapper"
                            // style={{ marginBottom: "-10px" }}
                          >
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                select
                                label="Blood Group"
                                type="text"
                                variant="outlined"
                                name="blood_group"
                                value={formdata?.blood_group}
                                onChange={handelchange}
                                required
                              >
                                <MenuItem value="A+">A+</MenuItem>
                                <MenuItem value="A-">A-</MenuItem>
                                <MenuItem value="B+">B+</MenuItem>
                                <MenuItem value="B-">B-</MenuItem>
                                <MenuItem value="O+">O+</MenuItem>
                                <MenuItem value="O-">O-</MenuItem>
                                <MenuItem value="AB+">AB+</MenuItem>
                                <MenuItem value="AB-">AB-</MenuItem>
                              </TextField>
                            </div>

                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                select
                                label="Gender"
                                type="text"
                                variant="outlined"
                                name="gender"
                                value={formdata?.gender}
                                onChange={handelchange}
                                required
                              >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                              </TextField>
                            </div>
                          </div>

                          <div className="two-field-wrapper">
                            <div className="editPetDetails-input-wrapper">
                              <TextField
                                label="Date of Birth"
                                variant="outlined"
                                type="date"
                                name="date_of_birth"
                                value={formdata?.date_of_birth}
                                onChange={handeldatechange2}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  max: new Date().toISOString().split("T")[0],
                                }}
                              />
                            </div>

                            <div
                              className="editPetDetails-input-wrapper"
                              id="select-pet-age"
                            >
                              <TextField
                                label="Age"
                                type="number"
                                variant="outlined"
                                // className="editPetDetails-input-tag"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={formdata?.age}
                                onChange={handelchange}
                                name="age"
                              />
                            </div>
                          </div>

                          <div className="two-field-wrapper">
                            <div style={{ width: "100%" }}>
                              <TextField
                                label="Identification Mark"
                                variant="outlined"
                                type="text"
                                // className="editPetDetails-input-tag"
                                maxLength="20"
                                value={formdata?.identity_mark}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                name="identity_mark"
                                onChange={handelchange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>

                    <List>
                      <ListItemButton onClick={() => setmenu2(!menu2)}>
                        <ListItemText>
                          <h5>Insurance Details</h5>
                        </ListItemText>
                        {menu2 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </List>
                    <Collapse in={menu2}>
                      <div className="sectiondiv">
                        <div className="insurancebox">
                          <TextField
                            label="Insurance Company"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={formdata?.insurance_company}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="insurance_company"
                            onChange={handelchange}
                          />
                        </div>
                        <div className="insurancebox">
                          <TextField
                            label="Insurance Number"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={formdata?.insurance_number}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="insurance_number"
                            onChange={handelchange}
                          />
                        </div>
                        <div className="insurancebox">
                          <TextField
                            label="Insurance Validity Upto"
                            variant="outlined"
                            type="date"
                            name="insurance_validity"
                            value={formdata?.insurance_validity}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handeldatechange}
                          />
                        </div>
                      </div>
                    </Collapse>
                    <List>
                      <ListItemButton onClick={() => setmenu3(!menu3)}>
                        <ListItemText>
                          <h5>Doctor Details</h5>
                        </ListItemText>
                        {menu3 ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </List>
                    <Collapse in={menu3}>
                      <div className="sectiondiv">
                        <div className="doctorbox">
                          <TextField
                            label="Doctor First Name"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={formdata?.doctor_firstname}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handelchange}
                            name="doctor_firstname"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Dr
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="doctorbox">
                          <TextField
                            label="Doctor Last Name"
                            variant="outlined"
                            type="text"
                            // className="editPetDetails-input-tag"
                            maxLength="20"
                            value={formdata?.doctor_lastname}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="doctor_lastname"
                            onChange={handelchange}
                          />
                        </div>
                        <div className="doctorbox">
                          <MuiPhoneInput
                            label="Doctor Mobile"
                            defaultCountry="in"
                            value={`+${formdata?.doctor_phcode} ${formdata?.doctor_contact}`}
                            onChange={phonehandler}
                            regions={[
                              "america",
                              "caribbean",
                              "asia",
                              "middle-east",
                              "oceania",
                              "europe",
                              "africa",
                            ]}
                            variant="outlined"
                            name="guardian_alternate_mobile"
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  edge="end"
                                  aria-label="personal-details-icon"
                                >
                                  <ContactPhoneIcon />
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Collapse>
                    <div className="editPetDetails-btn-row">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                      >
                        Next
                        {/* {this.state.redirecttopetparent ? "SUBMIT" : "NEXT"} */}
                      </Button>

                      <Button
                        type="button"
                        variant="contained"
                        style={{ width: "100%", color: "black" }}
                        className="bg-white mt-2 cancel-btn submit-login "
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          ) : null}
          {values === 1 ? (
            <>
              <div className="petParentDetails ">
                <p align="center">
                  <strong>Guardian Details</strong>
                </p>
                <div className="editParentDetails-form-container">
                  <div className="editParentDetails-form-container-bottom">
                    <form
                      className="editParentDetails-form"
                      onSubmit={guardiansubmit}
                    >
                      <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Guardian Firstname"
                            variant="outlined"
                            type="text"
                            name="guardian_firstname"
                            value={formdata?.guardian_firstname}
                            onChange={handelchange}
                            required
                          />
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Guardian Lastname"
                            variant="outlined"
                            type="text"
                            name="guardian_lastname"
                            value={formdata?.guardian_lastname}
                            onChange={handelchange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            name="guardian_email"
                            value={formdata?.guardian_email}
                            onChange={handelchange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                          />
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            select
                            label="Relation"
                            type="text"
                            variant="outlined"
                            name="typeof_guardian"
                            value={formdata?.typeof_guardian}
                            onChange={handelchange}
                            required
                          >
                            <MenuItem value="Son">Son</MenuItem>
                            <MenuItem value="Daughter">Daughter</MenuItem>
                            <MenuItem value="Father">Father</MenuItem>
                            <MenuItem value="Mother">Mother</MenuItem>
                            <MenuItem value="GrandFather">GrandFather</MenuItem>
                            <MenuItem value="GrandMother">GrandMother</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </TextField>
                        </div>
                      </div>

                      <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Alternate Email"
                            type="email"
                            variant="outlined"
                            name="guardian_alternate_email"
                            required
                            value={formdata?.guardian_alternate_email}
                            onChange={handelchange}
                          />
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <MuiPhoneInput
                            label="Number"
                            defaultCountry="in"
                            value={`+${formdata?.guardian_code} ${formdata?.guardian_mobile}`}
                            onChange={phonehandlerguardian}
                            required
                            regions={[
                              "america",
                              "caribbean",
                              "asia",
                              "middle-east",
                              "oceania",
                              "europe",
                              "africa",
                            ]}
                            name="guardian_mobile"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  edge="end"
                                  aria-label="personal-details-icon"
                                >
                                  <ContactPhoneIcon />
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                      </div>

                      <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <MuiPhoneInput
                            label="Alternate Number"
                            defaultCountry="in"
                            value={`+${formdata?.guardian_alternatecode} ${formdata?.guardian_alternate_mobile}`}
                            onChange={phonehandlerguardian2}
                            regions={[
                              "america",
                              "caribbean",
                              "asia",
                              "middle-east",
                              "oceania",
                              "europe",
                              "africa",
                            ]}
                            variant="outlined"
                            name="guardian_alternate_mobile"
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  edge="end"
                                  aria-label="personal-details-icon"
                                >
                                  <ContactPhoneIcon />
                                </IconButton>
                              ),
                            }}
                          />
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            select
                            label="Gender"
                            type="text"
                            variant="outlined"
                            maxLength="3"
                            name="guardian_gender"
                            value={formdata?.guardian_gender}
                            onChange={handelchange}
                            required
                          >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </TextField>
                        </div>
                      </div>

                      <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <FormControl>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ marginTop: "-7px", marginLeft: "13px" }}
                            >
                              Country
                            </InputLabel>
                            <Select
                              variant="outlined"
                              label="Country"
                              name="guardian_country"
                              required
                              value={
                                formdata?.guardian_country
                                  ?.charAt(0)
                                  .toUpperCase() +
                                formdata?.guardian_country?.slice(1)
                              }
                              onChange={handelchange}
                            >
                              {Country?.getAllCountries()?.map((c) => {
                                return (
                                  <MenuItem key={c.isoCode} value={c.name}>
                                    {c.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <FormControl>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ marginTop: "-7px", marginLeft: "13px" }}
                            >
                              State
                            </InputLabel>
                            <Select
                              disabled={
                                formdata?.guardian_country ? false : true
                              }
                              variant="outlined"
                              label="State"
                              name="guardian_state"
                              required
                              value={
                                formdata?.guardian_state
                                  ?.charAt(0)
                                  .toUpperCase() +
                                formdata?.guardian_state?.slice(1)
                              }
                              onChange={handelchange}
                            >
                              {State?.getStatesOfCountry(
                                countryWithIsoCode[0]?.isoCode
                              )?.map((s) => {
                                return (
                                  <MenuItem key={s.isoCode} value={s.name}>
                                    {s.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <FormControl>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ marginTop: "-7px", marginLeft: "13px" }}
                            >
                              City
                            </InputLabel>
                            <Select
                              variant="outlined"
                              disabled={formdata?.guardian_state ? false : true}
                              label="City"
                              required
                              name="guardian_city"
                              value={
                                formdata?.guardian_city
                                  ?.charAt(0)
                                  .toUpperCase() +
                                formdata?.guardian_city?.slice(1)
                              }
                              onChange={handelchange}
                            >
                              {City.getCitiesOfState(
                                countryWithIsoCode[0]?.isoCode,
                                getStateIsoCode[0]?.isoCode
                              )?.map((ct) => {
                                return (
                                  <MenuItem key={ct.isoCode} value={ct.name}>
                                    {ct.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Zip/Pin Code"
                            type="text"
                            variant="outlined"
                            // className="editParentDetails-input-tag"
                            name="guardian_pin"
                            value={formdata?.guardian_pin}
                            required
                            onChange={handelchange}
                          />
                        </div>
                      </div>

                      <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Address 1"
                            type="text"
                            variant="outlined"
                            name="guardian_address1"
                            value={formdata?.guardian_address1}
                            onChange={handelchange}
                            required
                          />
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Address 2"
                            type="text"
                            variant="outlined"
                            name="guardian_address2"
                            value={formdata?.guardian_address2}
                            onChange={handelchange}
                          />
                        </div>
                      </div>

                      <div className="two-field-wrapper"></div>
                      {/*  */}
                      <div className="editParentDetails-btn-row">
                        <Button
                          variant="contained"
                          type="submit"
                          className="submit-btn-editPet"
                        >
                          {/* {this.state.redirecttomedicalpage ? "NEXT" : "SUBMIT"} */}
                          Next
                        </Button>

                        <Button
                          style={{ color: "black" }}
                          variant="contained"
                          className="cancel-btn-editPet"
                          type="button"
                          onClick={() => {
                            setvalues(0);
                          }}
                        >
                          Back
                        </Button>
                      </div>

                      <Snackbar
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        autoHideDuration={8000}
                        action={[
                          <IconButton arial-label="Close" color="inherit">
                            X
                          </IconButton>,
                        ]}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {values === 2 ? (
            <>
              <div>
                <div
                  className="text-center"
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="medicalaclbutn">
                    <Button
                      startIcon={<AddIcon />}
                      style={{ backgroundColor: "#1976d2", color: "white" }}
                      variant="contained"
                      onClick={() => setnewrec(!addnewrec)}
                    >
                      ADD NEW MEDICAL
                    </Button>

                    <div style={{ margin: "3rem" }}>
                      <div className="editParentDetails-btn-row">
                        <Button
                          onClick={() => setopenmodal(!openmodal)}
                          variant="contained"
                          type="submit"
                          className="submit-btn-editPet"
                        >
                          {/* {this.state.redirecttomedicalpage ? "NEXT" : "SUBMIT"} */}
                          SUBMIT
                        </Button>

                        <Button
                          variant="contained"
                          style={{ color: "black" }}
                          className="cancel-btn-editPet"
                          type="button"
                          onClick={() => {
                            setvalues(1);
                          }}
                        >
                          back
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div id="meddetails">
                    {medicaltiles[0]?.Vaccination?.map((res) => {
                      return (
                        <>
                          <Medicalcard
                            medicaldetails={res}
                            type={"vaccine"}
                            activationpage={true}
                          />
                        </>
                      );
                    })}
                    {medicaltiles[0]?.Surgery?.map((res) => {
                      return (
                        <>
                          <Medicalcard
                            medicaldetails={res}
                            type={"Surgery"}
                            activationpage={true}
                          />
                        </>
                      );
                    })}
                    {medicaltiles[0]?.Allergy?.map((res) => {
                      return (
                        <>
                          <Medicalcard
                            medicaldetails={res}
                            type={"Allergy"}
                            activationpage={true}
                          />
                        </>
                      );
                    })}
                    {medicaltiles[0]?.Medication?.map((res) => {
                      return (
                        <>
                          <Medicalcard
                            medicaldetails={res}
                            type={"Medication"}
                            activationpage={true}
                          />
                        </>
                      );
                    })}
                    {medicaltiles[0]?.Medicalcondition?.map((res) => {
                      return (
                        <>
                          <Medicalcard
                            medicaldetails={res}
                            type={"Medicalcondition"}
                            activationpage={true}
                          />
                        </>
                      );
                    })}
                    {medicaltiles[0]?.Measurement?.map((res) => {
                      return (
                        <>
                          <Medicalcard
                            medicaldetails={res}
                            type={"Measurement"}
                            activationpage={true}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <Snackbar
        open={snackbar}
        autoHideDuration={8000}
        onClose={() => setsnackbar(false)}
        message={snackbarmsg}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        action={[
          <IconButton
            arial-label="Close"
            color="inherit"
            onClick={() => setsnackbar(false)}
          >
            X
          </IconButton>,
        ]}
      />
      {loader && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={
                        "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                      } 
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
      <Dialog
        open={openmodal}
        onClose={() => setopenmodal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your Tag details Updated"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wanted to link with Android app
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={skip}>Skip</Button>
          <Button onClick={linkapp} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          style: { borderRadius: 15, maxWidth: "835px" },
        }}
        id="AddRecordDialog"
        open={addnewrec}
        onClose={() => setnewrec(!addnewrec)}
        aria-labelledby="form-dialog-title"
      >
        <div>
          <div
            className="text-center"
            style={{
              margin: "auto",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <form
              className="addNewRecord-form"
              style={{ width: "100%" }}
              onSubmit={addmedical}
            >
              <Tabs
                value={value}
                onChange={() => setvalue(value)}
                aria-label="icon label tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                  "@media (min-width: 960px)": {
                    // Hide scroll buttons for screens wider than 960px
                    ".MuiTabs-scrollButtons": {
                      display: "none",
                    },
                  },
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                    alt="VaccinationImg"
                    className="img-medicla-tab"
                  />
                  <Tab
                    // icon={<PhoneIcon />}
                    label="Vaccination"
                    value={0}
                    onClick={() => setvalue(0)}
                  />
                </div>

                <div className="d-flex flex-column align-items-center">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                    alt="VaccinationImg"
                    className="img-medicla-tab"
                  />
                  <Tab
                    // icon={<Favorite />}
                    label="Surgery"
                    value={1}
                    onClick={() => setvalue(1)}
                  />
                </div>

                <div className="d-flex flex-column align-items-center">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                    alt="VaccinationImg"
                    className="img-medicla-tab"
                  />
                  <Tab
                    // icon={<PersonPinIcon />}
                    label="Allergy"
                    value={2}
                    onClick={() => setvalue(2)}
                  />
                </div>

                <div className="d-flex flex-column align-items-center">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                    alt="VaccinationImg"
                    className="img-medicla-tab"
                  />
                  <Tab
                    // icon={<PhoneIcon />}
                    label="Medication"
                    value={3}
                    onClick={() => setvalue(3)}
                  />
                </div>

                <div className="d-flex flex-column align-items-center">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                    alt="VaccinationImg"
                    className="img-medicla-tab"
                  />
                  <Tab
                    // icon={<Favorite />}
                    label="Medical Condition"
                    value={4}
                    onClick={() => setvalue(4)}
                  />
                </div>

                <div className="d-flex flex-column align-items-center">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
                    alt="VaccinationImg"
                    className="img-medicla-tab"
                  />
                  <Tab
                    // icon={<PersonPinIcon />}
                    label="Measurement"
                    value={5}
                    onClick={() => setvalue(5)}
                  />
                </div>
                {/* <ScrollableTabsButtonAuto /> */}
              </Tabs>
              {value === 0 && (
                <div className="addNewRecord-popup-wrapper">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Vaccination Name"
                      variant="outlined"
                      name="Vaccination_name"
                      value={medicaldetails?.Vaccination_name}
                      onChange={handelmedical}
                      required
                    />
                  </div>
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Doctor First Name"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="vaccinedoctor_firstname"
                      value={medicaldetails?.vaccinedoctor_firstname}
                      onChange={handelmedical}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Dr</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Doctor Last Name"
                      value={medicaldetails?.vaccinedoctor_lastname}
                      onChange={handelmedical}
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="vaccinedoctor_lastname"
                      required
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Administration Date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      onChange={handelmedical}
                      name="VaccinationAdministration_date"
                      value={medicaldetails?.VaccinationAdministration_date}
                      type="date"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="Notes"
                      // multiline
                      // rows={2}
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="VaccinationNotes"
                      value={medicaldetails?.VaccinationNotes}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}
                </div>
              )}

              {value === 1 && (
                <div className="addNewRecord-popup-wrapper">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Surgery Name"
                      variant="outlined"
                      name="Surgery_name"
                      value={medicaldetails?.Surgery_name}
                      onChange={handelmedical}
                      required
                    />
                  </div>
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Doctor Firstname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="surgerydoctor_firstname"
                      value={medicaldetails?.surgerydoctor_firstname}
                      onChange={handelmedical}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Dr</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Doctor Lastname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="surgerydoctor_lastname"
                      value={medicaldetails?.surgerydoctor_lastname}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Surgery Date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="Surgery_date"
                      value={medicaldetails?.Surgery_date}
                      onChange={handelmedical}
                      type="date"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <FormControl
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                    >
                      <InputLabel>Severity</InputLabel>
                      <Select
                        value={medicaldetails?.SurgerySeverity}
                        name="SurgerySeverity"
                        onChange={handelmedical}
                        label="Severity"
                      >
                        <MenuItem value="Minor">Minor</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="Major">Major</MenuItem>
                        <MenuItem value="Critical">Critical</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Notes"
                      variant="outlined"
                      rowsMin={2}
                      name="SurgeryNotes"
                      value={medicaldetails?.SurgeryNotes}
                      onChange={handelmedical}
                    />
                  </div>
                </div>
              )}

              {value === 2 && (
                <div className="addNewRecord-popup-wrapper">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Allergy Name"
                      variant="outlined"
                      name="Allergy_name"
                      value={medicaldetails?.Allergy_name}
                      onChange={handelmedical}
                      required
                    />
                  </div>
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      required
                      label="Doctor Firstname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="allergydoctor_firstname"
                      value={medicaldetails?.allergydoctor_firstname}
                      onChange={handelmedical}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Dr</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Doctor Lastname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="allergydoctor_lastname"
                      value={medicaldetails?.allergydoctor_lastname}
                      onChange={handelmedical}
                      required
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Diagnosis Date"
                      type="date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergyDiagnosis_date"
                      value={medicaldetails?.AllergyDiagnosis_date}
                      onChange={handelmedical}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      select
                      label="Severity"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergySeverity"
                      value={medicaldetails?.AllergySeverity}
                      onChange={handelmedical}
                      required
                    >
                      <MenuItem value="RAST 0 - Undetectable">
                        RAST 0 - Undetectable
                      </MenuItem>
                      <MenuItem value="RAST 1 - Low">RAST 1 - Low</MenuItem>
                      <MenuItem value="RAST 2- Moderate">
                        RAST 2- Moderate
                      </MenuItem>
                      <MenuItem value="RAST 3 - High">RAST 3 - High</MenuItem>
                      <MenuItem value="RAST 4 - Very High">
                        RAST 4 - Very High
                      </MenuItem>
                      <MenuItem value="RAST 5 - Ultra High">
                        RAST 5 - Ultra High
                      </MenuItem>
                      <MenuItem value="RAST 6 - Extremely High">
                        RAST 6 - Extremely High
                      </MenuItem>
                    </TextField>
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Notes"
                      variant="outlined"
                      rowsMin={2}
                      name="AllergyNotes"
                      value={medicaldetails?.AllergyNotes}
                      onChange={handelmedical}
                    />
                  </div>
                </div>
              )}

              {value === 3 && (
                <div className="addNewRecord-popup-wrapper">
                  <div className="addNewRecord-popup-wrapper-div">
                    {/* <label>Medication Name *</label> */}
                    <TextField
                      type="text"
                      label="Medication Name"
                      variant="outlined"
                      name="Medication_name"
                      value={medicaldetails?.Medication_name}
                      onChange={handelmedical}
                      required
                    />
                  </div>
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Doctor Firstname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="medicinedoctor_firstname"
                      value={medicaldetails?.medicinedoctor_firstname}
                      onChange={handelmedical}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Dr</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type="text"
                      label="Doctor Lastname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="medicinedoctor_lastname"
                      value={medicaldetails?.medicinedoctor_lastname}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
        
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    {/* <label>Medication Start Date *</label> */}
                    <TextField
                      type="date"
                      label="Medication Start Date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="Medication_startdate"
                      required
                      value={medicaldetails?.Medication_startdate}
                      onChange={handelmedical}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      type="date"
                      label="Medication End Date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="Medication_enddate"
                      value={medicaldetails?.Medication_enddate}
                      onChange={handelmedical}
                      // inputProps={{ min: Medication_startdate }}

                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    {/* <label>Dosage</label> */}
                    <TextField
                      type="text"
                      label="Dosage"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationDosage"
                      value={medicaldetails?.MedicationDosage}
                      onChange={handelmedical}
                    />
                    <TextField
                      className="addNewRecord-popup-wrapper-div-textarea"
                      label="Notes"
                      rowsMin={2}
                      variant="outlined"
                      name="MedicationNotes"
                      value={medicaldetails?.MedicationNotes}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
              </div> */}
                </div>
              )}

              {value === 4 && (
                <div className="addNewRecord-popup-wrapper">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Medical Condition Type"
                      variant="outlined"
                      name="Medicalcondition_type"
                      value={medicaldetails?.Medicalcondition_type}
                      onChange={handelmedical}
                      required
                    />
                  </div>
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Doctor Firstname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="medicaldoctor_firstname"
                      value={medicaldetails?.medicaldoctor_firstname}
                      onChange={handelmedical}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Dr</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type="text"
                      label="Doctor Lastname"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="medicaldoctor_lastname"
                      value={medicaldetails?.medicaldoctor_lastname}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="date"
                      label="Diagnosis Date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionDiagnosed_date"
                      value={medicaldetails?.MedicalconditionDiagnosed_date}
                      onChange={handelmedical}
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      type="text"
                      label="Severity"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionSeverity"
                      value={medicaldetails?.MedicalconditionSeverity}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      rowsMin={2}
                      label="Notes"
                      variant="outlined"
                      name="MedicalconditionNotes"
                      value={medicaldetails?.MedicalconditionNotes}
                      onChange={handelmedical}
                    />
                  </div>
                </div>
              )}

              {value === 5 && (
                <div className="addNewRecord-popup-wrapper">
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="number"
                      label="Weight (kg)"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="Weight"
                      value={medicaldetails?.Weight}
                      onChange={handelmedical}
                      required
                    />
                    <TextField
                      type="number"
                      label="Height (cm)"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="Height"
                      value={medicaldetails?.Height}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
              
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="date"
                      label="Measurement Date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="Measurement_date"
                      required
                      value={medicaldetails?.Measurement_date}
                      onChange={handelmedical}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      type="text"
                      label="Measured By"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="Measuredby"
                      value={medicaldetails?.Measuredby}
                      onChange={handelmedical}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      // rowsMin={2}
                      label="Notes"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="MeasurementNotes"
                      value={medicaldetails?.MeasurementNotes}
                      onChange={handelmedical}
                    />
                  </div>
                </div>
              )}

              <div className="addNewRecord-form-btn-wrapper">
                <button
                  type="submit"
                  className="addNewRecord-form-btn"
                  style={{ backgroundColor: "#ffc107", color: "white" }}
                >
                  Submit
                </button>

                <button
                  type="button"
                  onClick={() => setnewrec(!addnewrec)}
                  className="addNewRecord-form-btn"
                  style={{
                    color: "black",
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Humanactivation;
