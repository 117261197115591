import React from "react";
// import Navbar from '../header/header.component.jsx'
// import Footer from '../footer/footer.component.jsx'
import "./legalterms.component.css";
function Privacypolicy() {
  return (
    <div>
      <div className="legalterms">
        <h1 style={{ marginTop: "0px" }}>Privacy Policy</h1>
        <p>
          The Website is owned and operated by 3Y Ventures LLP, a Limited
          Liability Company incorporated under the laws of India. genie Smart
          Tag is a brand owned by 3Y Ventures LLP.
          <br></br>Our Privacy Policy is incorporated as part of the
          www.genie.tag8.in’s Conditions of Use. Please read this Privacy Policy
          and our Conditions of Use carefully before using our Services. By
          using www.genie.tag8.in, you agree to the provisions of this Privacy
          Policy. If you do not want to be bound by our Privacy Policy, please
          do not use our Services.{" "}
        </p>
        <h1>Why We Collect Information from You</h1>
        <p>
          To provide our Services, we use the information we collect to:
          <ul>
            <li>
              Help you register your pet for our lost & found recovery services
              to ensure that we are able to connect with you in case your pet is
              lost and the Finder calls in order to return your lost pet
            </li>
            <li>
              Connect you directly to the Finder in case your lost pet is found;
            </li>
            <li>Enable you to create a pet lost poster</li>
            <li>
              Enable you to store your pet’s veterinarian details and medical
              history, allergies and dietary habits, etc., and also to set
              vaccination, deworming or other medical or related, email alerts/
              reminders;
            </li>
            <li>Process your transactions;</li>
            <li>
              Administer a contest, promotion, survey or other site feature;
            </li>
            <li>Improve our website and/or customer service;</li>
            <li>
              Send you information and updates pertaining to your order, in
              addition to receiving occasional company news, updates, related
              product or service information, etc. It is also used to send you a
              one-time registration email when first setting up your account to
              confirm your password;
            </li>
            <li>
              Keep you connected with www.genie.tag8.in across the internet and
              update you with any lost & found related news and information.
            </li>
          </ul>
          <strong>
            {" "}
            Your information, whether public or private, will not be sold,
            exchanged, transferred, or given to any other company for any reason
            whatsoever, without your consent, other than for the express purpose
            of delivering the purchased product or service requested.{" "}
          </strong>
        </p>
        <h1>To Whom Does This Policy Apply</h1>
        <p>This Policy applies to everyone who uses our Services.</p>
        <h1>What Information Do We Collect, and Where Do We Get It</h1>
        <p>
          We collect some information from all visitors to our website: Like
          many online services, we use technologies like session and persistent
          cookies, web beacons (tiny image files on web pages that communicate
          information about the page viewer to the beacon owner), log data, and
          third-party analytics services to collect and analyse information
          about all users of our Services. This includes things like User search
          preferences, interaction with ads on our website and location.
          <br></br>Our servers automatically record information (‘Log Data’)
          created by your use of the Services. Log Data may include information
          such as your IP address, browser type, operating system, the referring
          web page, pages visited, location, your mobile carrier, device, search
          terms, and cookie information. We receive Log Data when you interact
          with our Services, for example, when you visit our websites, sign into
          our Services, or interact with our email notifications. We use Log
          Data to provide our Services and to measure, customise, and improve
          them, as well as collect and use them in the aggregate. This Policy
          does not apply to, nor do we take any responsibility for any
          information that is collected by any third party either using the
          Website or through any links on the Website or through any of the
          advertisements.
          <br></br>Cookies are small data files that we transfer to your
          computer. We use ‘session’ cookies to keep you logged in while you use
          our Services, to better understand how you interact with our Services,
          and to monitor aggregate usage and web traffic information on our
          Services. We use ‘persistent’ cookies to recognise you each time you
          return to our Services. For example, we create a persistent cookie
          that includes some basic information about you, like your most recent
          search and whether an agent responded to a query you sent using
          www.genie.tag8.in’s Services. We use this persistent cookie to
          remember your preferences and, if you create an account, to make your
          user experience consistent after you register.
          <br></br>Most internet browsers automatically accept cookies, but you
          can change your settings or use third-party tools to refuse cookies or
          prompt you before accepting cookies from the websites you visit. You
          can also use your browser settings or other tools to delete cookies
          you already have. Please be aware that some parts of our Services may
          not work for you if you disable cookies.
          <br></br>Location Data: If you provide location information (like a
          PIN code, street address, or even just a city) during the registration
          process or at any other time via your account settings, we will store
          that information and associate it with your account. We may use and
          store information about your location to provide localised features,
          to analyse our Services with the help of third-party analytics
          services, to provide you with relevant advertising and marketing
          materials, and otherwise to improve and customise our Services.{" "}
        </p>
        <h1>How We Share Your Information</h1>
        <p>
          As you use, access and navigate our website, we collect various
          information, which may either be personally identifiable information
          or information that is not personally identifiable. This information
          is classified as:
          <ul>
            <li>
              ‘Personal Information’ which means personally identifiable
              information like your name, your address, your age, your email
              address and your phone number.
            </li>
            <li>
              ‘Sensitive Personal Information’ which means your financial
              information, your passwords, any biometric, medical or
              health-related information.
            </li>
            <li>
              ‘Other Information’ which means all other information other than
              ‘Personal Information’ or ‘Sensitive Personal Information’. For
              example, the nature of device information, your usage patterns,
              your usage preferences, logs which are created when you access and
              use the Website, local caches on your browser, your location and
              IP address. These are just examples and the list is by no means
              exhaustive.
            </li>
          </ul>
          Any personal information will be used to allow you to login to your
          account or to resolve specific service issues, inform you of our new
          services or features and to communicate with you in relation to your
          use of the Website. Any other information we collect will be used for:
          <ul>
            <li>our business purposes;</li>
            <li>analytical purposes, data usage;</li>
            <li>improving the Website, or your user experience; and</li>
            <li>providing targeted advertisements to you.</li>
          </ul>
          We will share all information we collect from you, including the
          personal information with any third party if we in good faith believe
          it is required for the purposes of our compliance with any applicable
          law, regulation, legal process or enforceable governmental
          request/action or any manner required for the purposes of protecting
          our rights or interests.
          <br></br>From time to time, we make the e-mail addresses of those who
          access our Website available to other reputable organisations whose
          products or services we think you might find interesting. If you do
          not want us to share your e-mail address with other companies or
          organisations, please let us know by emailing us at support@tag8.in.
          <br></br>Persons who supply us with their telephone numbers online may
          receive telephone or text message contact from us with information
          regarding new products and services or upcoming events. If you do not
          wish to receive such telephone calls or text messages, please let us
          know by e-mailing us at support@tag8.in
          <br></br>
          <strong>With respect to Ad Servers:</strong>
          <br></br>To try and bring you offers that are of interest to you, we
          have relationships with other companies that we allow to place ads on
          our Web pages. As a result of your visit to our Website, ad server
          companies may collect information such as your domain type, your IP
          address and clickstream information.
          <br></br>
          <strong>Non-Personally Identifiable Information (Non-PII):</strong>
          <br></br>is information that cannot by itself be used to identify an
          individual person. Non-PII includes information received from the web
          browser, operating system, or device, including such things as
          information about the browser and browser settings, information about
          the operating system or device, the unique identifier assigned to a
          device, the web address of a web page visited, the name or type of
          mobile app, the time and date a web page or app was visited, the
          address of the referring web page, and other information about a web
          page or app. It could also include the number of times a particular
          browser or device has been shown a particular ad, or it may include
          generic categories about users and their interests. Non-PII also
          includes information about the precise geographic location of the
          device, which may be collected when the user of the device permits
          such collection.
          <br></br>
          <strong>Use of Non-PII:</strong>
          <br></br>In order to provide and improve our Services and to share
          relevant ads with you, provide interest-based advertising (to opt out
          of interest-based advertising on the Platform, write into
          support@tag8.in), show ads in a particular sequence, customise ads to
          a particular geographic region, determine when users respond to ads
          and reporting aggregated statistics regarding, for example, the
          effectiveness of online advertising campaigns we use and disclose to
          our advertising and analytics partners Non-PII that we collect,
          including cookie data, some log data, and mobile data.
          <br></br>
          <strong>User-Generated Content:</strong>
          <br></br>If you use our user-generated content services, like posting
          a question, answer or blog on our Website, you should be aware that
          any personally identifiable information you submit there can be read,
          collected, or used by other users and could be used to send you
          unsolicited messages. We are not responsible for the personally
          identifiable information you choose to submit in these forums.
          <br></br>
          <strong>Protecting Ourselves and Our Users:</strong>
          <br></br>We may release personal information when we believe that
          doing so is appropriate to comply with a legitimate law, regulation,
          or legal request; to enforce or apply our policies; to initiate,
          render, bill, and collect for amounts owed to us; to protect our
          rights or property; to protect the safety of our users; to address
          fraud, security, or technical issues; or if we reasonably believe that
          an emergency involving immediate danger of death or serious physical
          injury to any person requires disclosure of communications or
          justifies disclosure of records without delay.
          <br></br>
          www.genie.tag8.in website fully complies with all Indian Laws
          applicable. www.genie.tag8.in may disclose all or part of your
          personal details in response to a request from law enforcement
          authorities or in a case of a bona fide requirement to prevent an
          imminent breach of the law.{" "}
        </p>
        <h1>General Matters</h1>
        <p>
          <strong>
            The accuracy and confidentiality of your account information is your
            responsibility:
          </strong>
          <br></br>
          You are responsible for maintaining the secrecy and accuracy of your
          password, email address, and other account information at all times.
          We recommend a strong password that you do not use with other
          services. We are not responsible for personal data transmitted to a
          third party as a result of an incorrect email address.
          <br></br>
          <strong>Third-Party Websites:</strong>
          <br></br>
          Our Website may contain links to other websites. When you click on one
          of these links, you are navigating to another website. We do not
          accept liability for misuse of any information by any website
          controller to which we may link. We also do not have any
          responsibility of liability for the content on websites that use the
          www.genie.tag8.in search functionality. We encourage you to read the
          privacy statements of these websites, which may differ from ours.
          <br></br>
          <strong>Sale/Merger:</strong>
          <br></br>
          Information about our Users and our Website is a business asset of
          www.genie.tag8.in . Consequently, information about our Users,
          including personal information, will be disclosed as part of any
          merger or acquisition, creation of a separate business to provide the
          Website, our Services or fulfil products, sale or pledge of company
          assets as well as in the event of an insolvency, bankruptcy or
          receivership in which personal information would be transferred as one
          of the business assets of the company.
          <br></br>
          <strong>Security:</strong>
          <br></br>We use commercially reasonable security measures to protect
          the loss, misuse, and alteration of the information under our control.
          However, we cannot absolutely guarantee the protection of any
          information shared with us. When you enter sensitive information (such
          as a credit card number) on our order forms, we encrypt the
          transmission of that information using secure socket layer technology
          (SSL).
          <br></br>
          <strong>Policy Changes:</strong>
          <br></br>
          We may update this Privacy Policy to reflect changes to our
          information practices. If we make any change in how we use your
          personal information we will notify you by means of a notice on this
          Website prior to the change becoming effective. We encourage you to
          periodically review this page for the latest information on our
          privacy practices.{" "}
        </p>
        <h1>Changes to our Privacy Policy</h1>
        <p>
          If we decide to change our Privacy Policy, we will post those changes
          here. Please refer to this page on a continual basis{" "}
        </p>
        <h1>This policy was last modified on 3 February 2021</h1>
        <p>
          <strong>Your Consent</strong>
          <br></br>
          <br></br>By using our website, you consent to our Privacy Policy and
          Conditions of Use.{" "}
        </p>
      </div>
    </div>
  );
}

export default Privacypolicy;
