import React, { useEffect, useState } from "react";
import "./updaterecord.component.css";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Breadcrumbs,
  Button,
  Link,
  TextareaAutosize,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { switchUrls } from "../../../api/index.js";
function UpdateRecord({ medicaldetails, type, showupdate }) {
  const baseURL = switchUrls("human");
  const { tag_number } = useParams();
  const dateconvert = (dt) => {
    try {
      if (dt !== null) {
        let newDt = new Date(dt);
        const year = newDt.getFullYear();
        let month = newDt.getMonth() + 1;
        let day = newDt.getDate();
        month = month < 10 ? "0" + month : month;
        day = day < 10 ? "0" + day : day;
        const y = year + "-" + month + "-" + day;
        console.log(y, typeof y);

        return year + "-" + month + "-" + day;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [formdata, setformdata] = useState({ ...medicaldetails });
  const [value, setvalue] = useState(0);
  useEffect(() => {
    if (type === "vaccine") {
      setformdata({
        ...formdata,
        VaccinationAdministration_date: dateconvert(
          medicaldetails?.VaccinationAdministration_date
        ),
      });
      setvalue(0);
    } else if (type === "Allergy") {
      setformdata({
        ...formdata,
        AllergyDiagnosis_date: dateconvert(
          medicaldetails?.AllergyDiagnosis_date
        ),
      });
      setvalue(1);
    } else if (type === "Surgery") {
      setformdata({
        ...formdata,
        Surgery_date: dateconvert(medicaldetails?.Surgery_date),
      });
      setvalue(2);
    } else if (type === "Medication") {
      setformdata({
        ...formdata,
        Medication_enddate: dateconvert(medicaldetails?.Medication_enddate),
        Medication_startdate: dateconvert(medicaldetails?.Medication_startdate),
      });
      setvalue(3);
    } else if (type === "Medicalcondition") {
      setformdata({
        ...formdata,
        MedicalconditionDiagnosed_date: dateconvert(
          medicaldetails?.MedicalconditionDiagnosed_date
        ),
        Medicalcondition_enddate: dateconvert(
          medicaldetails?.Medicalcondition_enddate
        ),
      });
      setvalue(4);
    } else if (type === "Measurement") {
      setformdata({
        ...formdata,
        Measurement_date: dateconvert(medicaldetails?.Measurement_date),
      });
      setvalue(5);
    }
  }, [type]);

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  const handelmedical = (e) => {
    try {
      setformdata({ ...formdata, [e?.target?.name]: e?.target?.value });
    } catch (error) {
      console.log(error);
    }
  };
  const handelsubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(formdata);
      const { data } = await axios.post(
        `${baseURL}/api/human/updateMedicalDetails`,
        { formdata, tag_number, type },
        {
          headers: { Authorization: readCookie("token") },
        }
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
      window.location.reload();
    }
  };

  return (
    <>
      {type === "vaccine" ? (
        <>
          <form className="addNewRecord-form" onSubmit={handelsubmit}>
            <Tabs centered>
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                  alt="VaccinationImg"
                  className="img-medicla-tab"
                />
                <Tab
                  // icon={<PhoneIcon />}
                  label="Vaccination"
                  value={value}
                />
              </div>
            </Tabs>
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Vaccination Name"
                  variant="outlined"
                  name="Vaccination_name"
                  value={formdata?.Vaccination_name}
                  onChange={handelmedical}
                  required
                />
              </div>
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Doctor First Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="vaccinedoctor_firstname"
                  value={formdata?.vaccinedoctor_firstname}
                  onChange={handelmedical}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Dr</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Doctor Last Name"
                  value={formdata?.vaccinedoctor_lastname}
                  onChange={handelmedical}
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="vaccinedoctor_lastname"
                  required
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Administration Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  onChange={handelmedical}
                  name="VaccinationAdministration_date"
                  value={formdata?.VaccinationAdministration_date}
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Notes"
                  // multiline
                  // rows={2}
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="VaccinationNotes"
                  value={formdata?.VaccinationNotes}
                  onChange={handelmedical}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}
            </div>
            <div className="addNewRecord-form-btn-wrapper">
              <button
                type="submit"
                className="addNewRecord-form-btn"
                style={{ backgroundColor: "#ffc107", color: "white" }}
              >
                Submit
              </button>

              <button
                type="button"
                className="addNewRecord-form-btn"
                style={{
                  color: "black",
                }}
                onClick={() => showupdate(false)}
              >
                Close
              </button>
            </div>
          </form>
        </>
      ) : null}
      {type === "Allergy" ? (
        <>
          <form className="addNewRecord-form" onSubmit={handelsubmit}>
            <Tabs centered>
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                  alt="VaccinationImg"
                  className="img-medicla-tab"
                />
                <Tab
                  // icon={<PersonPinIcon />}
                  label="Allergy"
                  value={2}
                />
              </div>
            </Tabs>
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Allergy Name"
                  variant="outlined"
                  name="Allergy_name"
                  value={formdata?.Allergy_name}
                  onChange={handelmedical}
                  required
                />
              </div>
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  required
                  label="Doctor Firstname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="allergydoctor_firstname"
                  value={formdata?.allergydoctor_firstname}
                  onChange={handelmedical}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Dr</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Doctor Lastname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="allergydoctor_lastname"
                  value={formdata?.allergydoctor_lastname}
                  onChange={handelmedical}
                  required
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Diagnosis Date"
                  type="date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergyDiagnosis_date"
                  value={formdata?.AllergyDiagnosis_date}
                  onChange={handelmedical}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  select
                  label="Severity"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergySeverity"
                  value={formdata?.AllergySeverity}
                  onChange={handelmedical}
                  required
                >
                  <MenuItem value="RAST 0 - Undetectable">
                    RAST 0 - Undetectable
                  </MenuItem>
                  <MenuItem value="RAST 1 - Low">RAST 1 - Low</MenuItem>
                  <MenuItem value="RAST 2- Moderate">RAST 2- Moderate</MenuItem>
                  <MenuItem value="RAST 3 - High">RAST 3 - High</MenuItem>
                  <MenuItem value="RAST 4 - Very High">
                    RAST 4 - Very High
                  </MenuItem>
                  <MenuItem value="RAST 5 - Ultra High">
                    RAST 5 - Ultra High
                  </MenuItem>
                  <MenuItem value="RAST 6 - Extremely High">
                    RAST 6 - Extremely High
                  </MenuItem>
                </TextField>
              </div>

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  onChange={handelmedical}
                  label="Notes"
                  variant="outlined"
                  rowsMin={2}
                  name="AllergyNotes"
                  value={formdata?.AllergyNotes}
                />
              </div>
            </div>
            <div className="addNewRecord-form-btn-wrapper">
              <button
                type="submit"
                className="addNewRecord-form-btn"
                style={{ backgroundColor: "#ffc107", color: "white" }}
              >
                Submit
              </button>

              <button
                type="button"
                className="addNewRecord-form-btn"
                style={{
                  color: "black",
                }}
                onClick={() => showupdate(false)}
              >
                Close
              </button>
            </div>
          </form>
        </>
      ) : null}
      {type === "Surgery" ? (
        <>
          <form className="addNewRecord-form" onSubmit={handelsubmit}>
            <Tabs centered>
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                  alt="VaccinationImg"
                  className="img-medicla-tab"
                />
                <Tab
                  // icon={<Favorite />}
                  label="Surgery"
                  value={1}
                />
              </div>
            </Tabs>
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Surgery Name"
                  variant="outlined"
                  name="Surgery_name"
                  value={formdata?.Surgery_name}
                  onChange={handelmedical}
                  required
                />
              </div>
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Doctor Firstname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="surgerydoctor_firstname"
                  value={formdata?.surgerydoctor_firstname}
                  onChange={handelmedical}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Dr</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Doctor Lastname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="surgerydoctor_lastname"
                  value={formdata?.surgerydoctor_lastname}
                  onChange={handelmedical}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Surgery Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Surgery_date"
                  value={formdata?.Surgery_date}
                  onChange={handelmedical}
                  type="date"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <FormControl
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                >
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={formdata?.SurgerySeverity}
                    name="SurgerySeverity"
                    onChange={handelmedical}
                    label="Severity"
                  >
                    <MenuItem value="Minor">Minor</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Major">Major</MenuItem>
                    <MenuItem value="Critical">Critical</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Notes"
                  variant="outlined"
                  rowsMin={2}
                  name="SurgeryNotes"
                  value={formdata?.SurgeryNotes}
                  onChange={handelmedical}
                />
              </div>
            </div>
            <div className="addNewRecord-form-btn-wrapper">
              <button
                type="submit"
                className="addNewRecord-form-btn"
                style={{ backgroundColor: "#ffc107", color: "white" }}
              >
                Submit
              </button>

              <button
                type="button"
                className="addNewRecord-form-btn"
                style={{
                  color: "black",
                }}
                onClick={() => showupdate(false)}
              >
                Close
              </button>
            </div>
          </form>
        </>
      ) : null}
      {type === "Medication" ? (
        <>
          <form className="addNewRecord-form" onSubmit={handelsubmit}>
            <Tabs centered>
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                  alt="VaccinationImg"
                  className="img-medicla-tab"
                />
                <Tab
                  // icon={<PhoneIcon />}
                  label="Medication"
                  value={3}
                />
              </div>
            </Tabs>
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                {/* <label>Medication Name *</label> */}
                <TextField
                  type="text"
                  label="Medication Name"
                  variant="outlined"
                  name="Medication_name"
                  value={formdata?.Medication_name}
                  onChange={handelmedical}
                  required
                />
              </div>
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Doctor Firstname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="medicinedoctor_firstname"
                  value={formdata?.medicinedoctor_firstname}
                  onChange={handelmedical}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Dr</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="text"
                  label="Doctor Lastname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="medicinedoctor_lastname"
                  value={formdata?.medicinedoctor_lastname}
                  onChange={handelmedical}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
        
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                {/* <label>Medication Start Date *</label> */}
                <TextField
                  type="date"
                  label="Medication Start Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medication_startdate"
                  required
                  value={formdata?.Medication_startdate}
                  onChange={handelmedical}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="date"
                  label="Medication End Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medication_enddate"
                  value={formdata?.Medication_enddate}
                  onChange={handelmedical}
                  // inputProps={{ min: Medication_startdate }}

                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                {/* <label>Dosage</label> */}
                <TextField
                  type="text"
                  label="Dosage"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicationDosage"
                  value={formdata?.MedicationDosage}
                  onChange={handelmedical}
                />
                <TextField
                  className="addNewRecord-popup-wrapper-div-textarea"
                  label="Notes"
                  rowsMin={2}
                  variant="outlined"
                  name="MedicationNotes"
                  value={formdata?.MedicationNotes}
                  onChange={handelmedical}
                />
              </div>
            </div>
            <div className="addNewRecord-form-btn-wrapper">
              <button
                type="submit"
                className="addNewRecord-form-btn"
                style={{ backgroundColor: "#ffc107", color: "white" }}
              >
                Submit
              </button>

              <button
                type="button"
                className="addNewRecord-form-btn"
                style={{
                  color: "black",
                }}
                onClick={() => showupdate(false)}
              >
                Close
              </button>
            </div>
          </form>
        </>
      ) : null}
      {type === "Medicalcondition" ? (
        <>
          <form className="addNewRecord-form" onSubmit={handelsubmit}>
            <Tabs centered>
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                  alt="VaccinationImg"
                  className="img-medicla-tab"
                />
                <Tab
                  // icon={<Favorite />}
                  label="Medical Condition"
                  value={4}
                  disabled
                />
              </div>
            </Tabs>
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Medical Condition Type"
                  variant="outlined"
                  name="Medicalcondition_type"
                  value={formdata?.Medicalcondition_type}
                  onChange={handelmedical}
                  required
                />
              </div>
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Doctor Firstname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="medicaldoctor_firstname"
                  value={formdata?.medicaldoctor_firstname}
                  onChange={handelmedical}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Dr</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="text"
                  label="Doctor Lastname"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="medicaldoctor_lastname"
                  value={formdata?.medicaldoctor_lastname}
                  onChange={handelmedical}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="date"
                  label="Diagnosis Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionDiagnosed_date"
                  value={formdata?.MedicalconditionDiagnosed_date}
                  onChange={handelmedical}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="date"
                  label="Diagnosis Enddate"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medicalcondition_enddate"
                  value={formdata?.Medicalcondition_enddate}
                  onChange={handelmedical}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Severity"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionSeverity"
                  value={formdata?.MedicalconditionSeverity}
                  onChange={handelmedical}
                />
                <TextField
                  rowsMin={2}
                  label="Notes"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionNotes"
                  value={formdata?.MedicalconditionNotes}
                  onChange={handelmedical}
                />
              </div>
            </div>

            <div className="addNewRecord-form-btn-wrapper">
              <button
                type="submit"
                className="addNewRecord-form-btn"
                style={{ backgroundColor: "#ffc107", color: "white" }}
              >
                Submit
              </button>

              <button
                type="button"
                className="addNewRecord-form-btn"
                style={{
                  color: "black",
                }}
                onClick={() => showupdate(false)}
              >
                Close
              </button>
            </div>
          </form>
        </>
      ) : null}
      {type === "Measurement" ? (
        <>
          <form className="addNewRecord-form" onSubmit={handelsubmit}>
            <Tabs centered>
              <div className="d-flex flex-column align-items-center">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
                  alt="VaccinationImg"
                  className="img-medicla-tab"
                />
                <Tab
                  // icon={<PersonPinIcon />}
                  label="Measurement"
                  value={5}
                />
              </div>
            </Tabs>
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="number"
                  label="Weight (kg)"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Weight"
                  value={formdata?.Weight}
                  onChange={handelmedical}
                  required
                />
                <TextField
                  type="number"
                  label="Height (cm)"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Height"
                  value={formdata?.Height}
                  onChange={handelmedical}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
              
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="date"
                  label="Measurement Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Measurement_date"
                  required
                  value={formdata?.Measurement_date}
                  onChange={handelmedical}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="text"
                  label="Measured By"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Measuredby"
                  value={formdata?.Measuredby}
                  onChange={handelmedical}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  // rowsMin={2}
                  label="Notes"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="MeasurementNotes"
                  value={formdata?.MeasurementNotes}
                  onChange={handelmedical}
                />
              </div>
            </div>
            <div className="addNewRecord-form-btn-wrapper">
              <button
                type="submit"
                className="addNewRecord-form-btn"
                style={{ backgroundColor: "#ffc107", color: "white" }}
              >
                Submit
              </button>

              <button
                type="button"
                className="addNewRecord-form-btn"
                style={{
                  color: "black",
                }}
                onClick={() => showupdate(false)}
              >
                Close
              </button>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
}

export default UpdateRecord;
